import {DashboardProperties, DiagramProperties} from "../../types/type";
import {useSelector} from "react-redux";
import {LoraNode} from "../../../../../../model/lora-node/LoraNode";
import {List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {Diagram} from "../../Diagrams/Diagram";


export const DiagramList = (props: {dashboard: DashboardProperties, dashboardIdList:string[], setDragEnabled: (dashboardName: string, dragging: boolean, origin:"preview" | "dashboard",
                                                                                     diagramProperties: DiagramProperties | null, position: {     mouseX: number;     mouseY: number;     diagramX: number;     diagramY: number; }) => void,onDrag:(e:any) => void , onDragEnd:(x: number, y: number)=>void}
                            ) => {


    const diagrams = useSelector( (state:any) => state["DiagramDefinition"]?.items as DiagramProperties[])
    console.log("diagrams",diagrams)
    return <List sx={{width:"100%",height:"100%" , overflow:"scroll"}}>
        { diagrams ? diagrams.filter( diagram => !props.dashboardIdList.find( value => diagram.id === value) ).map( (diagram, index) => {
            console.log("diagram",diagram)
            return <ListItem key={index} >
                <Diagram diagramProps={diagram} positionProps={{posX:1, posY:1, sizeX:2, sizeY:2}} origin={"preview"}  width={"100%"} height={"9vw"} onDragEnd={props.onDragEnd} onDrag={props.onDrag} setDragStatus={props.setDragEnabled} />

            </ListItem>
        }): null}
    </List>


}