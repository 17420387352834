import {TileLayer, useMap} from "react-leaflet";
import React, {useEffect, useState} from "react";
import {Button, Fab, IconButton} from "@mui/material";
import {Layers, MapRounded} from "@mui/icons-material";

const TILE_LAYER_KEY = "mapTileLayer"
export const MapTileProvider = ( ) =>{

    const [selectedMap, setSelectedMap] = useState(0);

    useEffect(() => {
        let tileLayer = localStorage.getItem(TILE_LAYER_KEY);
        if(tileLayer && !isNaN(parseInt(tileLayer))){
            setSelectedMap(parseInt(tileLayer))
        }
    }, []);

    const mapTiles = [
        <TileLayer
            key={1}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxNativeZoom={19}
        maxZoom={25}
      />,

        <TileLayer
            key={2}
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            maxNativeZoom={19}
            maxZoom={25}
        />,

        <TileLayer
            key={3}
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
            maxNativeZoom={19}
            maxZoom={25}
        />,
    ]

    return <>
        <Fab color={"secondary"} sx={ { right:"0.5rem" , bottom:"0.5rem", position:"absolute", zIndex:1000} } onClick={() => {
            const newSelectedTile = (selectedMap+1)%mapTiles.length
            localStorage.setItem(TILE_LAYER_KEY, `${newSelectedTile}`)
            setSelectedMap( newSelectedTile)}}>

            <Layers/>
        </Fab>
        {mapTiles[selectedMap%mapTiles.length]}
    </>

}