import { NavigateOptions, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveComponent, setHit, setHitlist } from "../app-bar/appBarState";
import { setIsDirty } from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";


export const useMaterialOnFireNavigate = () =>{
  const reactRouterNavigate = useNavigate()
  const dispatch = useDispatch()

  const navigate = ( prefix: string, baseRoute:string, targetElement:string  , options? : NavigateOptions) => {
    dispatch(setActiveComponent(baseRoute))
    dispatch(setHit(null))
    dispatch(setHitlist([]))
    //todo check if we want to remove that on every route
    //dispatch(setIsDirty(false))
    reactRouterNavigate(`${prefix}/${baseRoute}${targetElement.length > 0 ? `/${targetElement}`:""}`, options);
  }

  return {navigate : navigate}
}

