import { useTranslation } from "../../../../MaterialOnFire/custom-hooks/useTranslation";
import {Grid, TextField, useEventCallback} from "@mui/material";
import { TCreateLoraNodeDataModel } from "../CreateLoraNodeWizardWrapper";
import React, {useEffect, useState} from "react";
import { useSnackbar } from "notistack";
import {GenericSelect} from "../../../../MaterialOnFire/GenericUIFields/GenericSelect/GenericSelect";
import {UiDescriptor} from "../../../../MaterialOnFire/GenericUIFields/MaintenanceView";

export const ProvideDeviceDetailsStep = (props: {
  newLoraNode: TCreateLoraNodeDataModel;
  updateNewLoraNode: (newState: TCreateLoraNodeDataModel) => void;
}) => {
  const { t } = useTranslation();
  const getNewLoraNode = useEventCallback(() => {
      return props.newLoraNode;
  })

  const { enqueueSnackbar } = useSnackbar();
  const [ coordinatesTakenOver , setCoordinatesTakenOver] = useState(false)
    const hardwareTypeUiDefinition = {
        fieldName: "hardwareType",
        required: true,
        reference: {
            id : "HardwareTypeUIDescriptor",
            model : "hardwareTypes",
        }
    } as UiDescriptor;

    const groupUiDefinition = {
        fieldName: "group",
        required: false,
        reference: {
            id : "GroupUIDescriptor",
            model : "groups",
        }
    } as UiDescriptor;

  useEffect(() => {
    if (navigator?.geolocation) {
      enqueueSnackbar(
        t(
          "createLoraNodeWizard.provideDeviceDetailsStep.tryToGetGeoCoordinates"
        ),
        { variant: "info" }
      );
    }
    const watchID = navigator?.geolocation?.watchPosition(
      (position) => {
        setCoordinatesTakenOver(true)
        console.log("took coordinates over")
        setTimeout( () => setCoordinatesTakenOver(false), 1500)

        props.updateNewLoraNode({
            ...getNewLoraNode(),
          lat: position.coords.latitude + ``,
          lng: position.coords.longitude + ``,
        });
      },
      () => {
        enqueueSnackbar(
          t(
            "createLoraNodeWizard.provideDeviceDetailsStep.GeoCoordinatesFailed"
          ),
          { variant: "error" }
        );
      }, {  }
    );

    return () => navigator?.geolocation?.clearWatch(watchID)
  }

  , []);
  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      <Grid item xs={6}>
        <TextField
          required={true}
          fullWidth
          focused={coordinatesTakenOver}
          label={t("createLoraNodeWizard.provideDeviceDetailsStep.lat")}
          value={props.newLoraNode?.lat || ""}
          onChange={(e) => {
            props.updateNewLoraNode({
              ...props.newLoraNode,
              lat: e.currentTarget.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required={true}
          fullWidth
          focused={coordinatesTakenOver}
          label={t("createLoraNodeWizard.provideDeviceDetailsStep.lng") }
          value={props.newLoraNode?.lng || ""}
          onChange={(e) => {
            props.updateNewLoraNode({
              ...props.newLoraNode,
              lng: e.currentTarget.value,
            });
          }}
        />
      </Grid>
        <Grid item xs={6}>
            <GenericSelect
                key={hardwareTypeUiDefinition.fieldName}
                translationPrefix={"loraNodes"}
                fieldKey={hardwareTypeUiDefinition.fieldName}
                value={props.newLoraNode?.hardwareType || "" }
                uiDescriptor={hardwareTypeUiDefinition}
                required={hardwareTypeUiDefinition.required}
                onChange={(value) => {
                    props.updateNewLoraNode({
                        ...props.newLoraNode,
                        hardwareType: value,
                    });
                }}
            />
        </Grid>
        <Grid item xs={6}>
            <GenericSelect
                key={groupUiDefinition.fieldName}
                translationPrefix={"loraNodes"}
                fieldKey={groupUiDefinition.fieldName}
                value={props.newLoraNode?.group || "" }
                uiDescriptor={groupUiDefinition}
                required={groupUiDefinition.required}
                onChange={(value) => {
                    props.updateNewLoraNode({
                        ...props.newLoraNode,
                        group: value,
                    });
                }}
            />
        </Grid>
    </Grid>
  );
};
