import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import {useMemo} from "react";
import {getEndpointUrlFromBackendConfig} from "./useGetKapionBackendClient";
import axios from "axios";
import {axiosHelper} from "./axiosHelper";
import { getApps } from "firebase/app";

interface KapionBackendKPIClient {
    updateDeviceKPIIndex: ( dev_eui : string, hardwareTypeId: string  ) => Promise<any>,
    updateHardwareTypeKPIIndex: (hardwareTypeId: string) => Promise<any>,
    updateAllKPIIndices: () => Promise<any>,
}

export const useGetKapionBackendKPIClient = () =>{





    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)
    const endpointUrl = useMemo(() => { return backendConfig? getEndpointUrlFromBackendConfig(backendConfig, "KPI"):"" }, [backendConfig])
    const axHelper = axiosHelper()


    const client = useMemo<KapionBackendKPIClient>(() => {

        return{
            updateDeviceKPIIndex: async (dev_eui,hardwareTypeId) => {
                const headers = await axHelper.getAuthHeader()
                const response = await axios.post(`${endpointUrl}/upsertDeviceKPIIndex`, {dev_eui, hardwareTypeId}, { headers : headers} )
                return response.data;
            },
            updateHardwareTypeKPIIndex: async (hardwareTypeId) => {
                const headers = await axHelper.getAuthHeader()
                const response = await axios.post(`${endpointUrl}/upsertHardwareTypeKPIIndex`, {hardwareTypeId}, { headers : headers} )
                return response.data;
            },
            updateAllKPIIndices: async () => {
                const headers = await axHelper.getAuthHeader()
                const response = await axios.post(`${endpointUrl}/upsertAllKPIIndices`, {}, { headers : headers} )
                return response.data;
            }

        }as KapionBackendKPIClient

    },[endpointUrl,backendConfig, axHelper])

    return client

}