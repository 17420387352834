import {useTranslation} from "../../../../../MaterialOnFire/custom-hooks/useTranslation";
import {Paper, Table, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import React from "react";

export const CustomTooltip = (props: { active: any, payload: any[], label: any }) => {
    const {t} = useTranslation()
    if (props.active && props.payload && props.payload.length) {
        return (
            <Paper key={"tooltip"} sx={{width: "25rem", opacity: "90%"}}>
                <Typography>{t("DiagramDefinition.diagramTooltip.time")} {props.payload[0].payload.time}</Typography>
                <TableContainer>
                    <Table key="table" size={"small"}>
                        {
                            props.payload.map((entry: any, index: number) => {
                                return <TableRow>
                                    <TableCell>
                                        <Typography color={entry.stroke||entry.color}>{entry.dataKey}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{entry.payload[entry.dataKey].toFixed(2)}</Typography>
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </Table>
                </TableContainer>
            </Paper>


        );
    }
    return <></>;

};