import { UiDescriptor } from "../GenericUIFields/MaintenanceView";

const childUIDescriptor: UiDescriptor[] = [
  {
    fieldName: "fieldName",
    fieldType: "textField",
    visible: true,
    required: true,
    size: 6,
  },
  {
    fieldName: "fieldLabel",
    fieldType: "textField",
    visible: true,
    required: false,
    size: 6,
  },

  {
    fieldName: "validator",
    fieldType: "textField",
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "fieldType",
    fieldType: "selectField",
    visible: true,
    required: true,
    size: 6,
  },
  {
    fieldName: "section",
    fieldType: "checkbox",
    visible: true,
    required: true,
    size: 6,
  },
  {
    fieldName: "required",
    fieldType: "checkbox",
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "sensitive",
    fieldType: "checkbox",
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "visible",
    fieldType: "checkbox",
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "size",
    fieldType: "selectField",
    visible: true,
    required: true,
    size: 6,
  },
  {
    fieldName: "reference",
    fieldType: "selectField",
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "mask",
    fieldType: "textField",
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "maskDefinition",
    fieldType: "textField",
    visible: true,
    required: false,
    size: 6,
  },
];

childUIDescriptor.push({
  fieldName: "childUIDescriptor",
  fieldType: "table",
  size: 12,
  visible: true,
  required: false,
  childUIDescriptor: [
    ...childUIDescriptor,
    {
      fieldName: "childUIDescriptor",
      fieldType: "table",
      size: 12,
      required: false,
      visible: true,
      childUIDescriptor: [...childUIDescriptor],
    },
  ],
});

const UIBuilderUIDescriptor: UiDescriptor[] = [
  {
    fieldName: "id",
    fieldType: "textField",
    visible: true,
    required: true,
    size: 6,
  },
  {
    fieldName: "Role",
    fieldType: "selectField",
    reference: {
      deleted: false,
      sensitive: false,
      id: "Roles",
      model: "Roles",
      name: "Roles",
      route: "/Roles/$",
      uiDescriptorDefinition: [],
    },
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "name",
    fieldType: "textField",
    visible: true,
    required: true,
    size: 6,
  },
  {
    fieldName: "icon",
    fieldType: "icon",
    visible: true,
    required: false,
    size: 6,
  },
  {
    fieldName: "model",
    fieldType: "textField",
    required: true,
    visible: true,
    size: 6,
  },
  {
    fieldName: "route",
    fieldType: "textField",
    validator: "^\\/[a-zA-Z]+\\/\\$$",
    required: true,
    visible: true,
    size: 6,
  },
  {
    fieldName: "uiDescriptorDefinition",
    fieldType: "table",
    required: false,
    visible: true,
    size: 12,
    childUIDescriptor: childUIDescriptor,
  },
];

export { UIBuilderUIDescriptor };
