import { Slider, Tooltip } from "@mui/material";
import React, {useEffect, useState} from "react";
import "./genericCookieSlider.css"

export const GenericCookieSlider = (props: {
    initialValue?: number;
    valueHelpPostfix?: string;
    valueHelpPrefix?: string;
    identifier: string;
    handleChange: (newValue: number) => void;
}) => {
    const [value, setValue] = useState<number>(Number.parseInt(localStorage.getItem(props.identifier) || (props.initialValue ? props.initialValue.toString() : "1")))

    useEffect(() => {
        props.handleChange(value);
    }, [])

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    }

    const handleCommittedChange = (event: any, newValue: any) => {
        localStorage.setItem(props.identifier, newValue.toString());
        props.handleChange(newValue);
    }

    const formatValueHelp = (value: number, index: number) => {
        return `${props.valueHelpPrefix ? props.valueHelpPrefix + " " : ""}${value}${props.valueHelpPostfix ? " " + props.valueHelpPostfix : ""}`
    }

    return <Tooltip
      title={value} placement={"bottom"} color="secondary" arrow={true} followCursor={true}><div><Slider className={"genericCookieSlider"}
                   defaultValue={value}
                   value={value}
                   step={3} marks min={1} max={50} valueLabelDisplay="off"
                   onChange={handleChange} onChangeCommitted={handleCommittedChange}
                                 valueLabelFormat={formatValueHelp} /> </div></Tooltip>
}