import {MaintenanceViewProps} from "../../../MaterialOnFire/GenericUIFields/MaintenanceView";
import {
    Box, Checkbox,
    FormControl,
    InputLabel, ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {GenericActionBar} from "../../../MaterialOnFire/GenericUIFields/GenericActionBar";
import {useCallback, useEffect, useRef, useState} from "react";
import {DiagramProperties} from "./types/type";
import {GenericTextEditField} from "../../../MaterialOnFire/GenericUIFields/GenericTextEditField";
import {KapionTextEditField} from "../../../MaterialOnFire/GenericUIFields/BaseComponents/KapionTextEditField";
import {EasySelect} from "../../../MaterialOnFire/GenericUIFields/BaseComponents/EasySelect";
import {Diagram} from "./Diagrams/Diagram";
import {useSelector} from "react-redux";
import {HardwareType} from "../../../../model/hardware-type/hardwareType";
import {KPIDocument} from "../../../nodeDetails/NodeDetails";
import {LoraNode} from "../../../../model/lora-node/LoraNode";

interface DiagramConfiguratorProps extends MaintenanceViewProps {}



const DIAGRAMTYPES: { id: string, label: string }[] = [{ id: "line" , label:"Linien-Diagramm"},
    { id: "bar" , label:"Balken-Diagramm"},
    { id: "pie" , label:"Kreis-Diagramm"},
    { id: "kpi" , label:"KPI-Card"}
]



// constanz AGGREGATION_STRATEGY containing id and label with based on the ids: "sum" , "mean" , "median" , "spread" ,"count";
const AGGREGATION_STRATEGY: { id: string, label: string }[] = [
    { id: "sum" , label:"Summe"},
    { id: "mean" , label:"Mittelwert"},
    { id: "median" , label:"Median"},
    { id: "spread" , label:"Spanne"},
    { id: "count" , label:"Anzahl"}
]





//constant AGGREGATION_INTERVAL that contains id and label for the follwing intervals "1m" | "5m" | "15m" | "1h" | "1d" | "1w" | "1M" | "1y"
const AGGREGATION_INTERVAL: { id: string, label: string }[] = [
    { id: "1m" , label:"1 Minute"},
    { id: "5m" , label:"5 Minuten"},
    { id: "15m" , label:"15 Minuten"},
    { id: "1h" , label:"1 Stunde"},
    { id: "1d" , label:"1 Tag"},
    { id: "1w" , label:"1 Woche"},
    { id: "1M" , label:"1 Monat"},
    { id: "1y" , label:"1 Jahr"},
]

//constant DURATION_UNIT that contains id and label for the follwing units "m"| "h"| "d"| "w"| "M"| "y"
const DURATION_UNIT: { id: string, label: string }[] = [
    { id: "m" , label:"Minute"},
    { id: "h" , label:"Stunde"},
    { id: "d" , label:"Tag"},
    { id: "w" , label:"Woche"},
    { id: "M" , label:"Monat"},
    { id: "y" , label:"Jahr"},
]




interface MultiSelectProps {
    label:string,
    width:string,
    values: { dbKey:string, label: string , checked:boolean }[]
    onChange: ( changedList:{ dbKey:string, label: string , checked:boolean }[]) => void,
    maxSelect?: number,

}


function MultiSelect(props: MultiSelectProps) {

    const [localState, setLocalState] = useState<{ dbKey:string, label: string , checked:boolean }[]>(props.values || [])
    const [selectedCount, setSelectedCount] = useState<number>(0)
    useEffect(() => {
        setLocalState(props.values || [])
        const selected = props.values?.filter( item => item.checked).length || 0
        setSelectedCount(selected)
    }, [props.values]);

    return <FormControl key={"MultiSelectFormControl"} sx={{m: 0, width: props.width}} variant="standard">
        <InputLabel id="MultiSelectFormControl-label">{props.label}{props.maxSelect !== undefined? `(${selectedCount}/${props.maxSelect})`:``}</InputLabel>
        <Select
            labelId="demo-multiple-checkbox-label"
            id="MultiSelectFormControl-select"
            multiple
            value={props.values || []}
            renderValue={() => <Typography>{localState.filter( item => item.checked).map( item => item.label).join(', ')}</Typography>}

        >
            {localState.map((item, index) => {
                const disabled = props.maxSelect !== undefined && selectedCount >= props?.maxSelect && !item.checked
                console.log("disabled", item.dbKey, disabled, selectedCount, props.maxSelect, item.checked)

                return <MenuItem key={"MultiSelectFormControl-menuitem-"+item.dbKey} value={item.label} disabled={disabled} onClick={()=>{
                    if(disabled)
                        return
                    const newState = [...localState]
                    newState.splice(index,1, {...item, checked: !item.checked})
                    setLocalState(newState)
                    props.onChange(newState)

                }}>
                    <Checkbox checked={item.checked} disabled={disabled}/>
                    <ListItemText primary={item.label} />
                </MenuItem>
            })}
        </Select>
    </FormControl>;
}

export const DiagramConfigurator = (props: DiagramConfiguratorProps) => {


    //redux Definitions
    const hardwareTypes: HardwareType[] = useSelector( (state:any) => state["hardwareTypes"]?.items as HardwareType[])
    const kpiMappings: KPIDocument[] = useSelector( (state:any) => state["KPIMapping"]?.items as KPIDocument[]  )
    const nodes:LoraNode[] = useSelector( (state:any) => state["loraNodes"]?.items as LoraNode[]  )

    //state Definitions
    const[diagramConfig, setDiagramConfig] = useState<DiagramProperties>(props.data as DiagramProperties)


    const [kpiList, setKpiList] = useState<{ id:string, label:string }[]>( [] );
    const [nodeList, setNodeList] = useState<{ id:string, label:string }[]>( [] );

    //ref Definitions
    const diagramConfigRef = useRef(diagramConfig);
    const updateDiagramConfig = useCallback((newConfig: DiagramProperties) => {
        diagramConfigRef.current = newConfig;
        setDiagramConfig(newConfig);
    },[])




    //Effect Definitions
    useEffect(() => {
        updateDiagramConfig({ ...props.data, influxQueryProperties: { ...props.data.influxQueryProperties, values: props.data.influxQueryProperties?.values ? props.data.influxQueryProperties.values :  [] }})
    }, [props.data?.id])

    useEffect(() => {
        const currentDiagramConfig = diagramConfigRef.current
        if(currentDiagramConfig.influxQueryProperties?.influxBucketName !== props.data.influxQueryProperties?.influxBucketName){

            updateDiagramConfig({...currentDiagramConfig, influxQueryProperties: { ...currentDiagramConfig.influxQueryProperties, nodeIds: [] , values:[]}})
        }
    },[props?.data?.influxQueryProperties?.influxBucketName,diagramConfig?.influxQueryProperties?.influxBucketName])

    useEffect(() => {

        const hardwareType = hardwareTypes.find( value => value.id === diagramConfig.influxQueryProperties?.influxBucketName)
        if(hardwareType){
            setKpiList( kpiMappings.filter( (mapping: any) => mapping.hardwareType?.id === hardwareType.id).map( (mapping :any ) => mapping.mappings).flat().map( (mapping: any) => { return{ label:mapping.name , id:mapping.id}}))
        }else{
            setKpiList([])
        }

    }, [diagramConfig?.influxQueryProperties?.influxBucketName,hardwareTypes,kpiMappings]);

    useEffect(() => {

        const hardwareType = hardwareTypes.find( value => value.id === diagramConfig.influxQueryProperties?.influxBucketName)
        if(hardwareType) {
            setNodeList(nodes.filter((node: any) => node.hardwareType?.id === hardwareType.id).map((node: any) => {
                return {label: node.name, id: node.id}
            }))
        }else{
            setNodeList([])

        }

    }, [diagramConfig?.influxQueryProperties?.influxBucketName,hardwareTypes,nodes]);



    return (

        <Box key="DiagramConfigContainer" display={"flex"} flexDirection={"column"} width={"100%"} height={"100%"}>
            <Box>
                <GenericActionBar key={"GenericActionBar"} state={diagramConfig} actions={props.actions}/>
            </Box>

            <Box key="ContentWrapper" display={"flex"} flexDirection={"row"} flexGrow={1} overflow={"hidden"} >
                <Box component={Paper} key="ConfigWrapper" display={"flex"} flexDirection={"column"} flexGrow={1} m={1}
                     width={"50%"} flexWrap={"wrap"} bgcolor={"white"} justifyContent={"flex-start"}
                     alignContent={"flex-start"} gap={"0.5rem"} overflow={"hidden"}>
                    <Box width={"100%"}>
                        <KapionTextEditField t={"DiagramDefinition.name"} required={true} id={"DiagramDefinition.name"}
                                             value={diagramConfig.name} type={"text"} disabled={false} valid={true}
                                             onChange={( e) => {

                                                 setDiagramConfig({...diagramConfig, name: e.currentTarget.value})
                                             }}/>
                    </Box>

                    <Box width={"100%"}> {<Typography variant={"h5"}> Abfrage Einstellungen</Typography>}</Box>

                    <EasySelect key={"diagramType"} translationKey={"DiagramDefinition.diagramProperties.diagramType"}
                                width={"100%"}
                                displayAsBox={true}
                                values={DIAGRAMTYPES}
                                value={diagramConfig.diagramProperties?.diagramType} updateSelected={(id: string) => {
                        if (id === "pie" || id === "bar" || id === "line" || id === "kpi")
                            updateDiagramConfig({
                                ...diagramConfig,
                                diagramProperties: {...diagramConfig.diagramProperties, diagramType: id}
                            })
                    }
                    }/>

                    <EasySelect key={"aggregationStrategy"}
                                translationKey={"DiagramDefinition.diagramProperties.aggregationStrategy"}
                                displayAsBox={true}
                                width={"100%"}
                                values={AGGREGATION_STRATEGY}
                                value={diagramConfig.diagramProperties?.aggregationStrategy}
                                updateSelected={(id: string) => {
                                    //@ts-ignore
                                    updateDiagramConfig({ ...diagramConfig,  diagramProperties: {...diagramConfig.diagramProperties, aggregationStrategy: id}
                                    })
                                }
                                }/>

                    <EasySelect key={"aggregationInterval"}
                                translationKey={"DiagramDefinition.diagramProperties.aggregationInterval"}
                                width={"100%"}
                                displayAsBox={true}
                                values={AGGREGATION_INTERVAL}
                                value={diagramConfig.diagramProperties?.aggregationInterval}
                                updateSelected={(id: string) => {
                                    //@ts-ignore
                                    updateDiagramConfig({ ...diagramConfig,   diagramProperties: {...diagramConfig.diagramProperties, aggregationInterval: id}
                                    })
                                }
                                }/>

                    <Box width={"100%"} hidden={true}>
                        <KapionTextEditField key={"startTime"} t={"DiagramDefinition.diagramProperties.startTime"}
                                             required={true} id={"DiagramDefinition.diagramProperties."}
                                             value={diagramConfig.diagramProperties?.startTime} type={"string"}
                                             disabled={true} valid={true} onChange={( e) => {

                            updateDiagramConfig({...diagramConfig, diagramProperties: { ...diagramConfig.diagramProperties, startTime:"now()"} })
                        }}/>
                    </Box>
                    <Box width={"100%"}>
                        <KapionTextEditField key={"duration"} t={"DiagramDefinition.diagramProperties.duration"}
                                             required={true} id={"DiagramDefinition.diagramProperties.duration"}
                                             value={diagramConfig.diagramProperties?.duration} type={"number"}
                                             disabled={false} valid={true} onChange={(e) => {
                            updateDiagramConfig({...diagramConfig, diagramProperties: { ...diagramConfig.diagramProperties, duration: e.currentTarget.value, startTime:"now()"} })
                        }}/>
                    </Box>

                    <EasySelect key={"durationUnit"} translationKey={"DiagramDefinition.diagramProperties.durationUnit"}
                                displayAsBox={true}
                                values={DURATION_UNIT}
                                width={"100%"}
                                value={diagramConfig.diagramProperties?.durationUnit} updateSelected={(id: string) => {
                        //@ts-ignore
                        updateDiagramConfig({                            ...diagramConfig,                            diagramProperties: {...diagramConfig.diagramProperties, durationUnit: id}
                        })
                    }
                    }/>


                    <Box width={"100%"}> {<Typography variant={"h5"}> Quellen Einstellungen</Typography>}</Box>

                    <EasySelect key={"hardwareType"}
                                translationKey={"DiagramDefinition.influxProperties.influxBucketName"}
                                displayAsBox={true}
                                values={hardwareTypes.map((hardwareType: HardwareType) => {
                                    return {id: hardwareType.id || "", label: hardwareType.name || ""}
                                })}
                                width={"100%"}
                                value={diagramConfig.influxQueryProperties?.influxBucketName || ""}
                                updateSelected={(id: string) => {
                                    //@ts-ignore
                                    updateDiagramConfig({
                                        ...diagramConfig,
                                        influxQueryProperties: {
                                            ...diagramConfig.influxQueryProperties,
                                            influxBucketName: id
                                        }
                                    })
                                }
                                }/>

                    <MultiSelect label={"Kennzahlen"} maxSelect={2} values={kpiList.map( kpi => ({
                        dbKey: kpi.label,
                        label: kpi.label,
                        checked: diagramConfig.influxQueryProperties.values ? diagramConfig.influxQueryProperties.values.indexOf(kpi.label) > -1 : false

                    }))} width={"100%"} onChange={(event) => {
                        updateDiagramConfig({
                            ...diagramConfig,
                            influxQueryProperties: {...diagramConfig.influxQueryProperties, values: event.filter(item => item.checked).map(item => item.dbKey)} //todo change to save actu
                        })}} />


                    <MultiSelect label={"Nodes"}  values={nodeList.map( node => ({
                        dbKey: node.id,
                        label: node.label,
                        checked: diagramConfig.influxQueryProperties.nodeIds ? diagramConfig.influxQueryProperties.nodeIds.indexOf(node.id) > -1 : false

                    }))} width={"100%"} onChange={(event) => {
                        updateDiagramConfig({
                            ...diagramConfig,
                            influxQueryProperties: {...diagramConfig.influxQueryProperties, nodeIds: event.filter(item => item.checked).map(item => item.dbKey)} //todo change to save actu
                    })}} />


                </Box>

                <Box key="PreviewWrapper" display={"flex"} flexDirection={"column"}  width={"70%"}
                     flexGrow={1} bgcolor={"white"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"}>
                    { diagramConfig ? <Diagram diagramProps={diagramConfig} width={"100%"} height={"100%"} origin={"config"}
                             onDragEnd={() => {
                             }} onDrag={() => {
                    }} setDragStatus={() => {
                    }} positionProps={{posX: 0, posY: 0, sizeX: 4, sizeY: 4}}/> : null
                    }
                </Box>


            </Box>

        </Box>
    )
}