import {useEffect, useMemo, useState} from "react";
import {AppBarAlertPipeline, registerAlertPipeline} from "../MaterialOnFire/app-bar/AppBapAlertManager";
import {
    useGetOfflineDevicesAlertPipeline
} from "./alertPipeLines/getOfflineDevicesAlertPipeline";


export const useRegisterAlertPipelines = () => {

        const fetchOfflineDevicesAlerts = useGetOfflineDevicesAlertPipeline();

       const pipelines = useMemo(() => {

        const lclPipelines: AppBarAlertPipeline = {
            "offlineDevices":  fetchOfflineDevicesAlerts
        }

        return lclPipelines;

    }, [fetchOfflineDevicesAlerts]);

    useEffect(() => {
        Object.keys(pipelines).forEach(key => {
            registerAlertPipeline(key, pipelines[key])
        })
    }, [pipelines]);


    return pipelines;


}


