import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import * as QrScanner from "qr-scanner";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../MaterialOnFire/custom-hooks/useTranslation";
import { CameraswitchOutlined, Close, Photo } from "@mui/icons-material";
import "./qrReader.css";

export const QRCodeReader = (props: {
  open: boolean;
  onClose: () => void;
  onComplete: (data: any) => void;
}) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [qrCodeFound, setQRCodeFound] = useState(false);
  const [qrCodeData, setQrCodeData] = useState<any>(null);
  const [selecedVideoDevice, setSelectedVideoDevice] = useState<MediaDeviceInfo|null>(null)
  const [ videoDevices , setVideoDevices] = useState<MediaDeviceInfo[]>([])
  const streamRef = useRef<MediaStream>(null);

  const snapshotVideo = () => {
    if (streamRef.current && canvasRef.current) {
      let ticks = streamRef.current.getVideoTracks();
      const latestTick = ticks[ticks.length - 1];

      const video = document.getElementById("qr-video");
      const context = canvasRef.current?.getContext("2d");

      const width = latestTick.getConstraints().width;
      const height = latestTick.getConstraints().height;
      const qrCode = document.getElementById("qr-code");
      qrCode.width = width;
      qrCode.height = height;
      context.drawImage(video, 0, 0, width, height);

      QrScanner.default
        .scanImage(canvasRef.current?.toDataURL(), {
          returnDetailedScanResult: true,
        })
        .then((result) => {
          setQRCodeFound(true);
          setQrCodeData(result.data);
          props.onComplete(result.data);
        })
        .catch((e) => {
          if (qrCodeFound) {
            setQRCodeFound(false);
          }
          if (e) {
            //todo implement suitable errorHandlingHere
            console.log(e);
          } else {
            if (qrCodeFound) {
              setQRCodeFound(false);
            }
          }
        });
    }
  };

  useEffect(  ()=> {
     navigator.mediaDevices.enumerateDevices().then( devices => {

       const videoDevices = devices.filter( device => device.kind === "videoinput")
       setSelectedVideoDevice(videoDevices[0]||null);
       setVideoDevices(videoDevices)

    })

  } ,[])

  useEffect(() => {
    setQRCodeFound(false);
    if (props.open) {
      let intervalRefresh = setInterval(() => snapshotVideo(), 500);
      let video = {}
      navigator.mediaDevices
        .getUserMedia({ video: { width: 400, height: 400, deviceId : selecedVideoDevice?.deviceId || ""  } })
        .then((stream) => {


          video = videoRef.current || {};
          video.srcObject = stream;
          streamRef.current = stream;

          video.play();
        })
        .catch((err) => {
          console.error("error:", err);
        });
      return () => {
        clearInterval(intervalRefresh);
        streamRef?.current?.getVideoTracks().forEach( track => track.stop() )
      };
    }
  }, [props.open, selecedVideoDevice]);

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogContent sx={{ width: "min-content", minWidth: "400px" }}>
        <Grid container spacing={2} sx={{ width: "min-content", minWidth: "400px" }}>
          <Grid item xs={12}>
            <DialogTitle
              sx={{
                paddingTop: 0,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography variant={"h6"} align={"center"}>
                {t("qrCodeReader.title")}{" "}
              </Typography>{" "}
            </DialogTitle>
          </Grid>
          <Grid item xs={12}>

          </Grid>
          <Grid item xs={12} sx={{ display: qrCodeFound ? "none" : undefined }}>
            <div className={qrCodeFound ? "contentQR" : "contentNoQR"}>
              <video
                className={"kapionVideo"}
                id="qr-video"
                style={{}}
                ref={videoRef}
              ></video>
              <Button sx={{position:"absolute", right: "0.5rem" , bottom:"0.5rem"}} variant={"contained"} color={"secondary"} onClick={ () => { if(selecedVideoDevice) {
                let index = videoDevices.findIndex( device => device.deviceId === selecedVideoDevice.deviceId)
                if(index >= 0) {
                  setSelectedVideoDevice(videoDevices[(index + 1) % videoDevices.length ])
                }

              }}}> <CameraswitchOutlined/></Button>
            </div>
          </Grid>
          <Box
            position={"absolute"}
            sx={{ visibility: "hidden", top: 0, left: 0 }}
          >
            <canvas id={"qr-code"} ref={canvasRef}></canvas>
          </Box>
          <Grid item xs={12}>
            {qrCodeFound ? (
              <TextareaAutosize
                style={{ width: "100%" }}
                value={JSON.stringify(qrCodeData, null, 2)}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{ justifyContent: "center", width: "100%" }}
          >
            {/**IconButton onClick={() => snapshotVideo()}>
              {" "}
              <Photo />
            </IconButton>*/}
            <IconButton
              onClick={() => props.onClose()}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
