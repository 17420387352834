import {
  BottomNavigation,
  BottomNavigationAction, Box, Collapse,
  Dialog,
  Divider,
  Grid, Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton, Pagination,
  Paper, Slider, Tab, Tabs, TextField,
  Tooltip, Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  MaintenanceView, MaintenanceViewProps,
  UiDescriptor
} from "../../GenericUIFields/MaintenanceView";
import {ReactNode, useEffect, useId, useRef, useState} from "react";
import { GenericActionBarAction } from "../../GenericUIFields/GenericActionBar";
import {  useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  setCurrentList,
  setIsDirty,
  setSelectedListItem,
} from "./GenericMaintenanceListState";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GenericConfirmDialog } from "../GenericConfirmDialog";
import {Close, Details, Edit, PushPin, WarningAmber} from "@mui/icons-material";
import { useTranslation } from "../../custom-hooks/useTranslation";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {GenericCookieSlider} from "../GenericCookieSlider";
import { useMaterialOnFireNavigate } from "../../custom-hooks/useMaterialOnFireNavigate";


const filterItemByString = ( item: any , filterValue:string|null ) : boolean => {
    let matchesAny = false;
    Object.keys(item).forEach( (value) =>{
      if(filterValue) {
        let matches = JSON.stringify(item[value]).includes(filterValue)
        if (matches)
          matchesAny = true
      }
      else{ matchesAny = true}
      if(filterValue===""){
        matchesAny = true
      }
    } ); return matchesAny}

const LIST_ITEM_HEIGHT = 50;
const LIST_ITEM_TOP_BOTTOM_PADDING = 4;

export const GenericMaintenanceList = (props: {
  list: any[];
  listItemDisplayFunction: (item: any) => ReactNode;
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
  maintenanceViewActions: GenericActionBarAction[];

  injectJSX?: React.ReactElement | React.ReactElement[];
  replacementJSX?: ( props:MaintenanceViewProps) => ReactNode;
  routingOptions: {
    idFieldName: string;
    baseRoute: string;
  };
  addNewAction?: () => void;
}) => {
  const [localList , setLocalList] = useState<any[]>([]);
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);

  const [selected, setSelected] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const {navigate} = useMaterialOnFireNavigate();

  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const theme = useTheme();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [navigationTarget, setNavigationTarget] = useState(false);
  const [confirmAction, setConfirmAction] = useState<"addNew" | "navigate">();
  const[ expanded, setExpanded] = useState(true);
  const [selectedView , setSelectedView] = useState(0)
  const [pinned, setPinned] = useState(true);

  const filterValue = useSelector( ( state:RootState) =>  state.appBar.searchString)

  const isDirty = useSelector(
    (state: RootState) => state.maintenanceList.isDirty
  );

  const getIdFieldValue = (value: any, idFieldName: string) => {
    let fields = idFieldName.split(".");
    let targetValue = value;

    for (let field of fields) {
      targetValue = targetValue[field];
    }

    return targetValue;
  };



  useEffect(() => {
    let index = localList.findIndex((value) => {
      let targetValue = getIdFieldValue(
        value,
        props.routingOptions.idFieldName
      );
      if (targetValue) {
        return targetValue === id;
      }
    });
    let item = index >= 0 ? localList[index] : null;
    setSelected(item);
    setSelectedView(0);
    setExpanded(true);
    dispatch(setSelectedListItem(item));
  }, [id, localList]);

  useEffect(() => {
    console.count("GenericMaintenanceList Props List Changes");
    setLocalList(props.list);
    dispatch(setCurrentList(props.list));
  }, [props.list]);

  useEffect( () =>{

    const newFilteredList:any[] = localList.filter( item => filterItemByString(item,filterValue));
    setFilteredList(newFilteredList);
    setSelectedPage(1);
    setNumberOfPages( Math.ceil(newFilteredList.length / pageSize) );
  } ,[filterValue,localList])


  const maintenanceViewProps = { data: selected, translationPrefix :props.translationPrefix, uiDescriptorTable: props.uiDescriptorTable , actions : props.maintenanceViewActions, hideFieldsWithoutUiDescriptor:true ,asSection:true}
  const listRef = useRef()

  useEffect(() => {
    //@ts-ignore
    const dimensions = listRef.current?.getBoundingClientRect()

    setPageSize(Math.floor(dimensions.height / ( LIST_ITEM_HEIGHT + LIST_ITEM_TOP_BOTTOM_PADDING )) )
  },[listRef.current])

  return (
    <Box
      display={"flex"}
      sx={{ height: "100%", width: "100%", overflow: "hidden" }}
    >
      <Box  sx={{height:"100%", width:"20%", m:0.5}} overflow={"hidden"} position={"relative"}>
        <Box component={Paper} display="flex" key={"RootPaper"} sx={{ height: "100%", width: "100%", p: 1, overflow: "auto"}} flexDirection={"column"} >

          <Box width={"100%"} display={"flex"} alignItems={"center"} flexDirection={"row"} >
            <Pagination size={"small"} sx={{ flexGrow: 1}} page={selectedPage} defaultPage={1} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setSelectedPage(value);
            }}count={numberOfPages} color="primary" />

            {props?.addNewAction ? (

                  <IconButton
                      onClick={() => {
                        setConfirmAction("addNew");
                        setConfirmDialogOpen(true);
                      }}
                  >
                    <AddCircleIcon />
                  </IconButton>

            ) : (
                <></>
            )}
          </Box>

          <Box width={"100%"} ref={listRef} flexGrow={1} overflow={"hidden"}>
            <List   sx={{height:"100%", width:"100%", overflow:"hidden"}}>
              {localList ? (
                filteredList.slice((selectedPage - 1)*pageSize,(selectedPage - 1 )*pageSize + pageSize).map((item: any, index: number) => {
                  const isSelected = selected
                    ? getIdFieldValue(
                        item,
                        props.routingOptions.idFieldName
                      ) ===
                        getIdFieldValue(
                          selected,
                          props.routingOptions.idFieldName
                        ) &&
                      getIdFieldValue(
                        selected,
                        props.routingOptions.idFieldName
                      )
                    : false;
                  return (
                    <>
                      <ListItemButton
                        key={index}
                        selected={isSelected}
                        sx={{ height: LIST_ITEM_HEIGHT, paddingTop: `${LIST_ITEM_TOP_BOTTOM_PADDING}px`, paddingBottom: `${LIST_ITEM_TOP_BOTTOM_PADDING}px`, width: "100%",  }}
                        onClick={() => {
                          setConfirmAction("navigate");
                          setConfirmDialogOpen(true);
                          setNavigationTarget(item);
                          setOpen(true);
                          setExpanded(false);
                        }}
                      >
                        {props.listItemDisplayFunction(item)}
                        {isSelected && isDirty ? (
                          <Tooltip  title={t("componentIsDirty")}>
                            <WarningAmber color={"warning"} />
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                      <Divider />
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </List>
          </Box>
        </Box>
      </Box>

      {
  (
        <Box
          display={"flex"}
          alignContent={"flex-start"}
          sx={{ height: "100%%" , m:0.5}}
          width={"80%"}
          flexDirection={"column"}
          key={"GenericMaintenanceViewContentGrid"}
          overflow={"hidden"}
        >
          <Paper key={"GenericMaintenanceListContentPaper"} sx={{ height: "100%", width: "100%", p: 1, overflow: "none" }}>
            {!!props.injectJSX ?<Tabs value={selectedView} onChange={ ( event, newValue) => {
              setSelectedView(newValue)
            }}  indicatorColor={"secondary"}>
              <Tab id={"0"}  label={<Typography color={ selectedView === 0 ? "secondary":"primary"}>{t("GenericMaitnenanceList.Maintab")}</Typography>}/>
              {  Array.isArray(props.injectJSX)  ? props.injectJSX.map( ( value,index ) => <Tab id={index+1+""} label={<Typography color={ selectedView === index+1 ? "secondary":"primary"}>{t(`${props.translationPrefix}.DetailsTab.${index}`)}</Typography>}/>)
                  : <Tab id={"1"} label={<Typography color={ selectedView === 1 ? "secondary":"primary"}>{t("GenericMaitnenanceList.DetailsTab")}</Typography>}/>

              }
            </Tabs>:null}
            {selected ? (
                selectedView === 0 ?
              props?.replacementJSX ? props.replacementJSX( maintenanceViewProps ) :<MaintenanceView
                key={selected?.id}
                data={selected}
                translationPrefix={props.translationPrefix}
                uiDescriptorTable={props.uiDescriptorTable}
                actions={props.maintenanceViewActions}
                hideFieldsWithoutUiDescriptor={true}
                asSection={true}
              /> : Array.isArray(props.injectJSX)? props.injectJSX[selectedView-1] : props.injectJSX
            ) : null}
          </Paper>
        </Box>
      )}
      <GenericConfirmDialog
        open={confirmDialogOpen}
        text={t("confirmDialog.confirmIsDirtyWarning")}
        canAutoConfirm={!isDirty}
        confirmAction={() => {
          if (confirmAction === "navigate") {
            navigate(
              "..",
                props.routingOptions.baseRoute
                ,
                getIdFieldValue(
                  navigationTarget,
                  props.routingOptions.idFieldName
                ),
              {
                replace: true,
              }
            );
          }

          if (confirmAction === "addNew") {
            if (props.addNewAction) props.addNewAction();
          }

          setConfirmDialogOpen(false);
          dispatch(setIsDirty(false));
        }}
        cancelAction={() => setConfirmDialogOpen(false)}
      />
    </Box>
  );
};
