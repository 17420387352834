import {useCallback, useEffect, useState} from "react";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import {UiEnhancements} from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import {GenericActionBarAction} from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import {
    readTTNDownlinkkMessage, readTTNDownlinkQueueMessages,
    readTTNUplinkMessage,
    TTNMessage,
} from "../../../model/lora-node/Messages/ttnMessages";
import {
    Box,
    Collapse,
    Divider,
    Grid,
    LinearProgress,
    ListItemButton,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import {getLoraNodeActionsFromHardWareType} from "./MaintainLoraNodeActions";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {HardwareType} from "../../../model/hardware-type/hardwareType";
import {NodeDetails} from "../../nodeDetails/NodeDetails";
import {useSnackbar} from "notistack";
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    RefreshOutlined, HourglassBottom, Clear, Check, Mail, HourglassDisabled,
} from "@mui/icons-material";
import {useTranslation} from "../../MaterialOnFire/custom-hooks/useTranslation";
import {useGetNodeStatus} from "../useGetNodeStatus";
import {CreateLoraNodeWizardWrapper} from "./createNewLoraDeviceWizzard/CreateLoraNodeWizardWrapper";
import {getFunctions, httpsCallable} from "firebase/functions";
import {fireBaseApp, firebaseDB} from "../../MaterialOnFire/firebase-config";
import {FeedbackSnackbar} from "../../FeedbackSnackbar";
import {collection, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {getEndpointUrlFromBackendConfig} from "./Dashboards/DiagramHelper/useGetDiagramData";
import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import axios from "axios";
import {getAuth} from "firebase/auth";
import {useGetKapionBackendClient} from "../kapionBackendClients/useGetKapionBackendClient";

interface MessageOpenInterface {
    [key: string]: boolean;
}

const prettyPrintJson = (payload: string) => {
    try {
        return (
            <pre style={{maxWidth: "100%", fontSize: 12}}>
        {" "}
                {JSON.stringify(JSON.parse(payload), null, 2)}{" "}
      </pre>
        );
    } catch (e) {
        return <pre> {payload}</pre>;
    }
};

const TimelineItem = (props: {
    isOpen: boolean;
    timestamp: string;
    payload: string;
    openCallback: () => void;
    item: TTNMessage;
}) => {
    const {t} = useTranslation();

    return (
        <Grid container height="100%">
            <Grid item xs={12} height="100%">
                <ListItemButton
                    onClick={props.openCallback}
                    sx={{height: "100%", p: 0}}
                >
                    <Grid container sx={{height: "100%"}}>
                        <Grid item xs={2} sx={{height: "100%"}}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="100%"
                            >
                                {props.isOpen ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                            </Box>
                        </Grid>
                        <Grid item xs={10}>

                            <Typography variant={"body2"}>{props.timestamp}</Typography>

                        </Grid>
                    </Grid>
                </ListItemButton>
              {getStatusIndicator(props.item)}
            </Grid>


            <Grid item xs={12}>
                <Collapse in={props.isOpen} timeout={"auto"} unmountOnExit={true}>
                    {props.item.type === "queue" ?
                        <>
                            <Typography>&nbsp; {t("NodeDetails.command")}: {props.item.instructionName}</Typography>
                            <Typography>&nbsp; {t("NodeDetails.by")}: {props.item.enqueuedBy}</Typography>
                        </>
                        : null}
                    {prettyPrintJson(props.payload)}
                </Collapse>
            </Grid>
        </Grid>
    );
};

const MessageTimeline = (props: { selectedNode?: LoraNode }) => {
    const [messageOpen, setMessageKeyOpen] = useState<MessageOpenInterface>({});
    const [allSortedMessages, setAllSortedMessages] = useState<(TTNMessage)[]>([]);
    const [newTTNUplinkMessages, setNewTTNUplinkMessages] = useState<Array<TTNMessage>>([]);
    const [newTTNDownlinkkMessages, setNewTTNDownlinkkMessages] = useState<Array<TTNMessage>>([]);
    const [newTTNDownlinkQueueMessagesSnapshot, setNewTTNDownlinkQueueMessagesSnapshot] = useState<any>(null);
    const [initialLoaded, setInitialLoaded] = useState<boolean>(false);


    useEffect(() => {
        const unsubscribeFunctions: any[] = [];
        if (props.selectedNode?.ttnPayload) {
            setInitialLoaded(false);

            Promise.all([
                readTTNUplinkMessage(props.selectedNode?.ttnPayload?.ids?.device_id),
                readTTNDownlinkkMessage(props.selectedNode?.ttnPayload?.ids?.device_id),
                readTTNDownlinkQueueMessages(props.selectedNode.ttnPayload?.ids?.device_id)
            ]).then(([uplink, downlink, queued]) =>{
                setAllSortedMessages(sortByTime([...uplink,...downlink,...queued]));
                setInitialLoaded(true);
            });

//TODO: Fix updates not getting in when multiple messages are queued
        const downlinkQueueMessages = query(
            collection(firebaseDB, "/downlinkQueue"),
            where("payload.deviceId", "==", props.selectedNode.ttnPayload?.ids?.device_id),
            orderBy("enqueuedAt", "desc"),
            limit(1));

        unsubscribeFunctions.push(onSnapshot(downlinkQueueMessages, (downlinkQueueMessagesSnapshot) => {
            let newMessages : Array<TTNMessage> = [];
            let modified : Array<TTNMessage> = [];
            let deleted : Array<TTNMessage> = [];

            downlinkQueueMessagesSnapshot.docChanges().forEach((change) => {
                const data = change.doc.data();
                const message : TTNMessage = {
                    time: new Date(data.enqueuedAt) || "",
                    type: "queue",
                    status: data.status,
                    enqueuedBy: data.enqueuedBy,
                    payload: data.payload.instruction.join(" "),
                    instructionName : data.payload?.instructionName
                }

                if (change.type === "added") {
                        newMessages.push(message)
                }
                if (change.type === "modified") {
                    modified.push(message)
                }
           })

            setNewTTNDownlinkQueueMessagesSnapshot({
                added : newMessages,
                modified : modified,
                deleted : deleted
            });
        }));

            const TTNUplinkMessage = query(
                collection(firebaseDB, "/ttnMessage/ttnMessage/uplink"),
                where("end_device_ids.device_id", "==", props.selectedNode.ttnPayload?.ids?.device_id),
                orderBy("received_at", "desc"),
                limit(1)
            );

            unsubscribeFunctions.push(onSnapshot(TTNUplinkMessage, (uplinkMessagesSnapshot) => {
                let newMessages : Array<TTNMessage> = [];
                uplinkMessagesSnapshot.docChanges().forEach((change) => {
                    const data = change.doc.data();
                    const message: TTNMessage = {
                        time: new Date(data?.received_at) || "",
                        type: "uplink",
                        payload: JSON.stringify(data?.uplink_message?.decoded_payload) || "",
                    }

                    if (change.type === "added") {
                        newMessages.push(message)
                    }
                })

                setNewTTNUplinkMessages(newMessages);
            }));

            const TTNDownlinkkMessage = query(
                collection(firebaseDB, "/ttnMessage/ttnMessage/downlinkSent"),
                where("end_device_ids.device_id", "==", props.selectedNode.ttnPayload?.ids?.device_id),
                orderBy("received_at", "desc"),
                limit(1)
            );

            unsubscribeFunctions.push(onSnapshot(TTNDownlinkkMessage, (downlinkMessagesSnapshot) => {
                let newMessages : Array<TTNMessage> = [];
                downlinkMessagesSnapshot.docChanges().forEach((change) => {
                    const data = change.doc.data();
                    const message: TTNMessage = {
                        time: new Date(data?.received_at) || "",
                        type: "downlink",
                        payload: data?.downlink_sent?.decoded_payload?.bytes?.toString() || "",
                    }

                    if (change.type === "added") {
                        newMessages.push(message)
                    }
                })

                setNewTTNDownlinkkMessages(newMessages);
            }));
        }

        return () => {
            for (let unsub of unsubscribeFunctions) {
                try {
                    unsub();
                } catch (e) {
                    console.error("Couldn't unsubscribe");
                }
            }
        };
    }, [props.selectedNode]);

    useEffect(() => {
        if(initialLoaded){
            setAllSortedMessages(sortByTime([...allSortedMessages, ...newTTNUplinkMessages]));
        }
    }, [newTTNUplinkMessages])

    useEffect(() => {
        if(initialLoaded) {
            setAllSortedMessages([...allSortedMessages, ...newTTNDownlinkkMessages]);
        }
    }, [newTTNDownlinkkMessages])

    useEffect(() => {
        if(initialLoaded && newTTNDownlinkQueueMessagesSnapshot != null){
            let messages = [...allSortedMessages];
            messages = addTo(messages, newTTNDownlinkQueueMessagesSnapshot.added)
            messages = deleteFrom(messages, newTTNDownlinkQueueMessagesSnapshot.deleted)
            messages = modifyIn(messages, newTTNDownlinkQueueMessagesSnapshot.modified)

            setAllSortedMessages(sortByTime(messages));
        }
    }, [newTTNDownlinkQueueMessagesSnapshot])

    const sortByTime = (toBeSorted : Array<TTNMessage>) : Array<TTNMessage> => {
        let array : Array<TTNMessage> = [...toBeSorted];
        return array.sort((a: TTNMessage, b: TTNMessage) => {
            return b.time.getTime() - a.time.getTime();
        });
    }

    const addTo = (oldMessages: any, toBeAdded : any) => {
        return [...oldMessages, ...toBeAdded]
    }

    const deleteFrom = (oldMessages: any, toBeDeleted : any) => {
        let messages : Array<TTNMessage> = [...oldMessages];
        toBeDeleted.forEach((toBeDel : TTNMessage) => {
            let index = messages.findIndex((message) => message.time.getTime() === toBeDel.time.getTime())
            if(index !== -1){
                messages.splice(index, 1)
            }
        })
        return messages
    }

    const modifyIn = (oldMessages: any, toBeUpdated : any) => {
        let messages : Array<TTNMessage> = [...oldMessages];

        toBeUpdated.forEach((updated : TTNMessage) => {
            let index = messages.findIndex((message) => {
                return message.time.getTime() === updated.time.getTime()
            })
            if(index !== -1){
                messages[index] = updated;
            }
        })
        return messages;
    }


    return (
        <>
            <Grid
                container
                item
                xs={12}
                sx={{height: "85%", width: "100%", overflow:"auto"}}
            >
                {allSortedMessages.map((line, index) => {
                    return (
                        <Grid item container xs={12}>
                            <Grid
                                item
                                xs={5}
                                sx={{height: "100%", p: 0, minHeight: "75px"}}
                            >
                                {index % 2 === 0 ? (

                                    <Grid container
                                          display="flex"
                                          minWidth="250px"
                                          component={Paper}
                                    >
                                        <TimelineItem
                                            isOpen={messageOpen[index]}
                                            timestamp={line.time.toLocaleString("de-DE")}
                                            payload={line.payload}
                                            item={line}
                                            openCallback={() => {
                                                const map = {...messageOpen};
                                                map[index] = !map[index];
                                                setMessageKeyOpen(map);
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>


                            <Grid item xs={2} >
                              <Divider orientation={"vertical"} sx={{marginTop : "-10px"}}>
                                    {getIconForTypeAndStatus(line)}
                          </Divider>
                            </Grid>


                            <Grid
                                item
                                xs={5}
                                sx={{height: "100%", p: 1, minHeight: "75px"}}
                                alignContent={"center"}
                                justifyContent={"center"}
                            >
                                {index % 2 !== 0 ? (
                                    <Grid container
                                          display="flex"
                                          minWidth="200px"
                                          component={Paper}
                                    >
                                        <TimelineItem
                                            isOpen={messageOpen[index]}
                                            timestamp={line.time.toLocaleString("de-DE")}
                                            payload={line.payload}
                                            item={line}
                                            openCallback={() => {
                                                const map = {...messageOpen};
                                                map[index] = !map[index];
                                                setMessageKeyOpen(map);
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

const getIconForTypeAndStatus = (line : TTNMessage) => {
    switch (line.type){
        case "uplink" : return <ArrowCircleUpIcon color={"primary"}/>
        case "downlink" : return <ArrowCircleDownIcon color={"primary"}/>
        case "queue" : switch (line.status){
            case  "enqueued" : {
                let enqueuedAt = line.time.getTime();
                let now = Date.now();

                if(now-enqueuedAt >= 7.5 * 60 * 1000){
                    return <HourglassDisabled color={"warning"}/>;
                }
                return <Mail color={"primary"}/>

            }
            case "successful" : return <Check color={"secondary"}/>
            case "failed" : return <Clear color={"error"}/>
        }
    }
}

const getStatusIndicator = (line: TTNMessage) => {
  if(line.type !== "queue"){
    return <LinearProgress value={100} variant="determinate" color={"secondary"}/>;
  }
    switch (line.status) {
        case "enqueued":{
            let enqueuedAt = line.time.getTime();
            let now = Date.now();

            if(now-enqueuedAt >= 7.5 * 60 * 1000){
                return <LinearProgress value={100} variant="determinate" color={"warning"}/>
            }else {
                return <LinearProgress color={"secondary"}/>
            }
        }

        case "successful":
            return <LinearProgress value={100} variant="determinate" color={"secondary"}/>
        case "failed" :
            return <LinearProgress value={100} variant="determinate" color={"error"}/>
        default :
            return <LinearProgress value={100} variant="determinate" color={"secondary"}/>;
    }
}

export const useGetLoraNodesUIEnhancer = () => {
    const cachedHardwaretypes = useSelector(
        (
            state: RootState // @ts-ignore
        ) => state["hardwareTypes"]?.items as unknown as HardwareType[]
    );

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const {backendKPIClient} =  useGetKapionBackendClient()

    //Die GenericMaintenanceList führt das selected Item( in dem Fall die Lora Node)
    //im Redux State mit
    const selected = useSelector(
        (state: RootState) => state.maintenanceList.selected as LoraNode
    );

    const nodeStatus = useGetNodeStatus({id: selected?.id});
    const loraNodeActionBarEnhancer = (
        item: LoraNode,
        actions: GenericActionBarAction[]
    ) => {


        let saveAction = actions[0];
        let originalSaveAction = saveAction.action;

        saveAction.action = async ( newState, uiDefinition) => {
            await originalSaveAction( newState, uiDefinition);
            if (newState.hardwareType && newState.id){

                const key = Date.now();
                enqueueSnackbar(<FeedbackSnackbar  closeSnackbar={closeSnackbar} promise={ backendKPIClient.updateDeviceKPIIndex(newState.id,newState.hardwareType.id)}
                                                   componentIndicator={"upsertDeviceKPIIndex"}
                                                  snackbarKey={key}/>, {variant: "default", persist: true, key: key})

            }
        }

            // at position 1 there is the generated delete action
            let deleteAction = actions[1];
            deleteAction.action = () => {
                const functions = getFunctions(fireBaseApp, "europe-west1");
                const purgeDevice = httpsCallable(functions, "purgeDevice");

                const purgePromise = purgeDevice({
                    dev_eui: item.id,
                    applicationId: item.ttnPayload.ids.application_ids.application_id,
                    deviceId: item.ttnPayload.ids.device_id,
                })
                const key = Date.now();
                enqueueSnackbar(<FeedbackSnackbar closeSnackbar={closeSnackbar} promise={purgePromise}
                                                  snackbarKey={key}/>, {variant: "default", persist: true, key: key})
            }

            if (item?.hardwareType) {
                const hardwareInstructions = getLoraNodeActionsFromHardWareType(
                    item?.hardwareType,
                    cachedHardwaretypes,
                    enqueueSnackbar,
                    closeSnackbar,
                );
                return [...actions, ...hardwareInstructions];
            }
            return actions;
        };


    const loraNodeEnhancer = {
        enhanceActionBar: loraNodeActionBarEnhancer,
        newWizard: <CreateLoraNodeWizardWrapper/>,
        injectJSX: [
                    <Paper sx={{height: "100%", overflow:"hidden"}}>
                        <Grid container sx={{height: "100%", width: "100%"}} spacing={2}>
                            <Grid item xs={8} sx={{height: "2rem"}}>
                                <Typography variant={"h6"} align={"center"}>
                                    {t("NodeDetails.Measurements")}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={4}
                                alignItems={"center"}
                                justifyContent={"left"}
                                sx={{height: "2rem"}}
                            >
                                <Tooltip
                                    title={
                                        <div>
                                            {t("NodeDetails.LastTransferred")}
                                            {(
                                                nodeStatus.timeSinceLastMessage /
                                                60 /
                                                60 /
                                                1000
                                            ).toFixed(2)}
                                            {t("Stunden")}
                                        </div>
                                    }
                                >
                                    <Typography variant={"h6"}>Status:</Typography>
                                </Tooltip>
                                {
                                    <div
                                        style={{
                                            backgroundColor: nodeStatus.color,
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            borderRadius: "50%",
                                            marginLeft: "0.2rem"
                                        }}
                                        color={nodeStatus.color}
                                    ></div>
                                }
                            </Grid>
                            <Grid item xs={12} sx={{height: "100%"}} overflow={"hidden"}>
                                {<NodeDetails id={selected?.id} maxWidth={"500px"} maxHeight={"300px"}/>}
                            </Grid>
                        </Grid>
                    </Paper>
                ,

                    <Paper id="timeline-paper" sx={{height: "99.9%", overflow:"hidden", width: "100%"}}>
                        <Grid container sx={{height: "100%", width: "100%"}} spacing={2}>
                            <Grid item xs={12} sx={{height: "5%"}}>
                                <Typography variant={"h6"} align={"center"}>
                                    {t("NodeDetails.Messages")}
                                </Typography>
                            </Grid>

                            <MessageTimeline selectedNode={selected}/>
                        </Grid>
                    </Paper>

        ],
    } as UiEnhancements;

    return {key: "loraNodes", enhancer: loraNodeEnhancer};
};
