import { useSnackbar } from "notistack";
import { GenericActionBarAction } from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fireBaseApp } from "../../MaterialOnFire/firebase-config";
import { useTranslation } from "../../MaterialOnFire/custom-hooks/useTranslation";
import { Gateway } from "../../../model/lora-node/gateway";
import { UiEnhancements } from "../../MaterialOnFire/custom-hooks/useGetRoutes";

export const useGetGatewayUIEnhancer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const gatewayActionBarEnhancer = (
    item: Gateway,
    actions: GenericActionBarAction[]
  ) => {
    const saveAction = actions[0];
    const deleteAction = actions[1];

    const newSaveAction = (newState: Gateway) => {
      saveAction.action(newState);

      const functions = getFunctions(fireBaseApp, "europe-west1");
      const getRegisterNewGateway = httpsCallable(
        functions,
        "registerNewGateway"
      );

      getRegisterNewGateway({
        ...newState,
        applicationId: newState.application.id,
      })
        .then(() => {
          enqueueSnackbar(t("gateway.createSuccessful"), {
            variant: "success",
          });
        })
        .catch((e) => {
          console.log(e);
          enqueueSnackbar(t("gateway.createFailed"), {
            variant: "error",
          });
        });
    };

    const newDeleteAction = (newState: Gateway) => {
      deleteAction.action(newState);

      const functions = getFunctions(fireBaseApp, "europe-west1");
      const getPurgeGateway = httpsCallable(functions, "purgeGateway");

      getPurgeGateway({
        ...newState,
        applicationId: newState.application.id,
      })
        .then(() => {
          enqueueSnackbar(t("gateway.purgeSuccessful"), {
            variant: "success",
          });
        })
        .catch((e) => {
          console.log(e);
          enqueueSnackbar(t("gateway.purgeFailed"), {
            variant: "error",
          });
        });
    };

    return [
      {
        ...saveAction,
        action: newSaveAction,
        disabled: item?.lastModified != null,
      },
      { ...deleteAction, action: newDeleteAction },
    ];
  };

  const gatewayUIEnhancer = {
    enhanceActionBar: gatewayActionBarEnhancer,
  } as UiEnhancements;

  return { key: "Gateway", enhancer: gatewayUIEnhancer };
};
