import {Box} from "@mui/material";
import {AnalyticsTrend} from "./LandingPageAnalyticTiles/AnalyticsTrend";
import {AnalyticsPieChart} from "./LandingPageAnalyticTiles/AnalyticsPieChart";


export const LandingPageAnalytics = ( ) =>{

        //todo implement hook to fetch influxDB data
    return <Box width={"100%"} height={"200px"} display={"flex"} flexDirection={"row"} justifyContent={"space-evenly"} alignContent={"center"}  >
        <AnalyticsPieChart width={"15%"} label={"Aktive Teilnehmer"} data={ {positive:15, negative:30}} />
        <AnalyticsTrend width={"15%"}  label={"Messwerte -7 Tage"} data={ { previous : 100000 , current:111000}}/>
        <AnalyticsTrend width={"15%"}  label={"Messwerte -24 H"} data={ { previous : 1550 , current:1500}}/>
        <AnalyticsPieChart width={"15%"} label={"Freie Parkplätze"} data={ {positive:95, negative:5}} />
        <AnalyticsTrend width={"15%"}  label={"Ø Luftdruck -7 Tage"} data={ { previous : 9000 , current:12000}}/>


    </Box>

}