import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "../../../MaterialOnFire/custom-hooks/useTranslation";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AddCircle, Delete, Rocket } from "@mui/icons-material";
import { EventActionType } from "./EventsUI";
import { useSelector } from "react-redux";
import { Group } from "../../../../model/groups/groupState";
import { HardwareType } from "../../../../model/hardware-type/hardwareType";
import { EasySelect } from "../../../MaterialOnFire/GenericUIFields/BaseComponents/EasySelect";
import DeleteIcon from "@mui/icons-material/Delete";


const ActionRow = ( props: {
  action: EventActionType,
  index: number,
  updateState: (index:number, action: EventActionType ) => void,
  deleteRow: (index:number) => void,

}) => {

  const groups = useSelector( (state:any) => state["groups"]?.items as Group[])
  const hardwareType = useSelector( (state:any) => state["hardwareTypes"]?.items as HardwareType[])
  const [currentGroup, setCurrentGroup] = useState<Group | null>(groups.find( group => group.id === props.action.groupId) || null);
  const [availableInstructions, setAvailableInstructions ] = useState<{id:string,label:string}[]>(hardwareType
      .find( type => type.id === currentGroup?.hardwareType?.id )?.instructions?.filter( instruction => instruction.multicast)
      .map( instruction => { return { id: instruction.id , label : instruction.name}} )||[])

  useEffect( () => {
    const group = groups.find( group => group.id === props.action.groupId)
    if(group) {
      setAvailableInstructions( hardwareType
        .find( type => type.id === group.hardwareType?.id )?.instructions?.filter( instruction => instruction.multicast)
        .map( instruction => { return { id: instruction.id , label : instruction.name}} )||[])
      if(group.id !== currentGroup?.id){
        props.updateState(props.index,{...props.action, instructionId :""})
      }
      setCurrentGroup(group);
    }else{
      setAvailableInstructions([])
      setCurrentGroup(null);
    }
  },[props.action.groupId, hardwareType])

  return <TableRow>
    <TableCell padding={"checkbox"}>
    <IconButton onClick={ () => {props.deleteRow(props.index)}}> <Delete/></IconButton>
    </TableCell>
    <TableCell>
      <EasySelect xs={12} key={props.action.groupId +"-Group"} translationKey={"Event.Group"} value={props.action.groupId} values={groups.map( group => { return {label:group.name, id:group.id}})} updateSelected={ (id) =>{
        props.updateState(props.index,{...props.action, groupId :id, applicationId: groups.find( group => group.id === id)?.loraSettings.application.id || ""})
      }}/>
    </TableCell>
    <TableCell>
      <EasySelect xs={12}disabled={availableInstructions.length ===0} key={props.action.groupId +"-" +props.action.instructionId} translationKey={"Event.Instruction"} value={props.action.instructionId} values={availableInstructions} updateSelected={ (id) =>{
        props.updateState(props.index,{...props.action, instructionId :id})
      }}/>
    </TableCell>

  </TableRow>
}

export const ActionsTable = (props:{ addButton?:ReactNode , actions : EventActionType[], deleteRow: ( index:number) => void, updateState : (index:number, action:EventActionType) => void} ) => {
  const { t } = useTranslation()

  return <TableContainer>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell padding={"checkbox"}>
            {props.addButton}
          </TableCell>
          <TableCell>
            {t("Event.Group")}
          </TableCell>
          <TableCell>
            {t("Event.Instruction")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

        {props.actions?.map( (action, index) => <ActionRow key={index} action={action} index={index} deleteRow={props.deleteRow} updateState={props.updateState}/>)}

      </TableBody>
    </Table>
  </TableContainer>


}