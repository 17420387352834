import {useEffect, useMemo, useState} from "react";
import {AppBarAlert, getAlertPipelines} from "../app-bar/AppBapAlertManager";
import AppBar from "@mui/material/AppBar";
import {useGetFirebaseConfigParameter} from "./useGetFirebaseConfigParameter";
import {ALERT_SETTINGS_PARAMETER_NAME, alertSettings} from "./types/firebaseRemoteConfigTypes";




export const useGetAlerts = () => {
    const [alerts, setAlerts] = useState<AppBarAlert[]>([])


    const alertSettings = useGetFirebaseConfigParameter<alertSettings>(ALERT_SETTINGS_PARAMETER_NAME)

   useEffect( () => {
       const fetchAlerts = async () => {
           console.log('fetching alerts')
           const pipelines = getAlertPipelines()
           console.log('pipelines', pipelines)
           const alertPromises = Object.keys(pipelines).map(key => pipelines[key].fetchAlerts())

           const fetchedAlerts = await Promise.all(alertPromises)
           console.log('fetched alerts', fetchedAlerts)
           setAlerts(fetchedAlerts.flat(2))
       }

       fetchAlerts()
       if(alertSettings?.AllertPollRate) {
           const interval = setInterval(fetchAlerts, alertSettings?.AllertPollRate)
           return () => clearInterval(interval)
       }



    },[alertSettings])

    return alerts;
}