import {UiEnhancements} from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import {DiagramConfigurator} from "./Dashboards/DiagramConfigurator";

export const useGetDiagramConfigEnhancer = () => {


    const diagramDefinitionEnhancer = {
        replacementJSX: ( props  ) =>{

            return <DiagramConfigurator {...props} />
        } } as UiEnhancements;

    return { key: "DiagramDefinition", enhancer: diagramDefinitionEnhancer };


}