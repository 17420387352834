import {Autocomplete, ListItem, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import { setHit, setSearchString } from "./appBarState";
import {useTranslation} from "../custom-hooks/useTranslation";
import { useEffect, useRef } from "react";

export const SearchComponent = () => {
    const hitlist = useSelector((state: RootState) => state.appBar.hitList)
    const activeComponent = useSelector((state : RootState) => state.appBar.activeComponent)
    //TODO: add component to appsState, put component identifier into key
    //const sdf = useSelector( (state:RootState) => state.appBar.component)
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const textFieldRef = useRef<any>(null);

    useEffect( () => {
            if(textFieldRef?.current){
                textFieldRef?.current.focus()
            }
            dispatch(setHit(null))
            dispatch(setSearchString(""))
    },[activeComponent, textFieldRef.current])

    return <Autocomplete ref={textFieldRef} key={activeComponent} freeSolo sx={{ width: 300 }} options={hitlist} onChange={(event, value) => {
        if(typeof value !== "string"){
            console.log(value)
            dispatch(setHit(value))
        }
    }}  renderInput={(params) => <TextField sx={ { input :{ color : "white"} , backgroundColor:"rgba(68,67,67,0.25)"}}

                                            key={activeComponent+"-textField"} variant="standard" {...params} autoFocus={true} onChange={ (event)=>{ dispatch(setSearchString(event.currentTarget.value))}} placeholder={t("list.filterField")}/>}/>
}