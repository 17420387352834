import { GenericFieldInterface } from "./GenericFieldInterface";
import { useState } from "react";
import {
  Badge, Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  Tooltip, Typography,
} from "@mui/material";
import { useGetIconList } from "../custom-hooks/useGetIconList";
import { Collections, OpenInNew } from "@mui/icons-material";
import { IconChooser } from "../UI-Builder/IconChooser";
import { useTranslation } from "../custom-hooks/useTranslation";
import * as React from "react";

export interface GenericIconFieldInterface
  extends Omit<GenericFieldInterface, "onChange"> {
  onChange: (e: string) => void;
}
export const GenericIconField = (props: GenericIconFieldInterface) => {
  const [open, setOpen] = useState(false);
  const icons = useGetIconList();
  const { t } = useTranslation();
  return (
    <Grid item xs={props.uiDescriptor.size}>
      <Box display={"flex"} width={"100%"} height={"100%"} padding={"0.5rem"} alignContent={"center"} justifyContent={"center"}>
        <Typography sx={{width:"33%"}} >
          { props.required ? <Badge badgeContent={"*"}> {t(`${props.translationPrefix}.${props.fieldKey}`)} </Badge> : t(`${props.translationPrefix}.${props.fieldKey}`) }
        </Typography>
      <FormControl component="fieldset" fullWidth required={props.required}>
        {/*
        <InputLabel id={props.uiDescriptor.fieldName}>
          {t(`${props.translationPrefix}.${props.fieldKey}`)}
        </InputLabel>*/
        }
        <Select
          value={props.value}
          open={false}
          variant={"standard"}
          renderValue={() => {
            return props.value ? icons.getIcon(props.value) : <></>;
          }}
          onOpen={() => setOpen(true)}
        ></Select>
      </FormControl>

      {open && (
        <IconChooser
          open={open}
          onSelectCallback={(e) => {
            props.onChange(e);
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      )}
      </Box>
    </Grid>
  );
};
