import { UiEnhancements } from "../../MaterialOnFire/custom-hooks/useGetRoutes";
import { EventsUI } from "./EventUI/EventsUI";


export const useGetEventUIEnhancer = () =>{


  const eventEnhancer = {
    replacementJSX : ( props  ) =>{
      return <EventsUI {...props}/>
    }
  } as UiEnhancements;
  return { key: "Event", enhancer: eventEnhancer };

}
