

export interface AppBarAlert {
    key: string;
    title: string;
    message: string;
    link: string;
    severity: "error" | "warning" | "info" | "success";
}

export interface AppBarAlertPipeline {
    [key: string]: {
        fetchAlerts: () => Promise<AppBarAlert[]>;
    }
}

const alertPipelines: AppBarAlertPipeline = {};

export const getAlertPipelines = ()=>{
    return alertPipelines;
}

export const getRegisteredAlertPipelines = () => {
    return Object.keys(alertPipelines);
}

export const getAlertPipeline = (key: string) => {
    return alertPipelines[key];
}

export const registerAlertPipeline = (key: string, pipeline: AppBarAlertPipeline[keyof AppBarAlertPipeline]) => {
    alertPipelines[key] = pipeline;
}