import { MaintenanceViewProps, UiDescriptor } from "../../../MaterialOnFire/GenericUIFields/MaintenanceView";
import {Checkbox, FormControlLabel, Grid, IconButton, TextField} from "@mui/material";
import { GenericActionBar } from "../../../MaterialOnFire/GenericUIFields/GenericActionBar";
import { LoraNode } from "../../../../model/lora-node/LoraNode";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "../../../MaterialOnFire/custom-hooks/useTranslation";
import { AddCircle } from "@mui/icons-material";
import { ActionsTable } from "./ActionsTable";
import { EasySelect } from "../../../MaterialOnFire/GenericUIFields/BaseComponents/EasySelect";
import { UiDefinition } from "../../../MaterialOnFire/UI-Builder/uiBuilderServices/uiDefinition";


export interface EventActionType{
  groupId: string,
  instructionId: string,
  applicationId: string,
}
interface EventsType{
  id: string,
  name: string,
  active: boolean,
  loraNodeId: string,
  kpiId: string,
  condition: string,
  blockingTime: number,
  startTime: string,
  endTime:string,
  actions: EventActionType[],
  executionDelay:number,
  numberOfExecutions: number,


}

const getHasErrorFunction = ( fieldName : string, descriptors: UiDescriptor[]) => {

  const descriptor = descriptors.find( desc => desc.fieldName === fieldName)
  return ( value:string) => {
    if(descriptor){
      if(descriptor.validator){
        const regex = new RegExp(descriptor.validator)
        return !regex.test(value)
      }
    }
    return false
  }}


export const EventsUI = ( props: MaintenanceViewProps ) => {
  const [localState, setLocalState] = useState<EventsType>(props.data  as EventsType)
  const loraNodes = useSelector( (state:any) => state["loraNodes"]?.items as LoraNode[])
  const kpiMappings = useSelector( (state:any) => state["KPIMapping"]?.items  )
  const [loraNode, setLoraNode] = useState<LoraNode|undefined>(loraNodes.find( value => value.id === localState.loraNodeId));

  const conditionHasError = useCallback( () =>{
    const conditionHasError = getHasErrorFunction("condition",props.uiDescriptorTable)
    return conditionHasError(localState?.condition)

  }, [props.uiDescriptorTable,localState?.condition])

  const [ availableKPIList, setAvailableKPIList] = useState<{ id:string, label:string }[]>( kpiMappings.filter( (mapping: any) => mapping.hardwareType?.id === loraNode?.hardwareType?.id).map( (mapping :any ) => mapping.mappings).flat().map( (mapping: any) => { return{ label:mapping.name , id:mapping.id}}));

  useEffect( () => {
    setLocalState(props.data as EventsType)
  },[props.data?.id])

  useEffect( () => {
    const lora = loraNodes.find( value => value.id === localState.loraNodeId)
    if(lora){
      setAvailableKPIList( kpiMappings.filter( (mapping: any) => mapping.hardwareType?.id === lora.hardwareType?.id).map( (mapping :any ) => mapping.mappings).flat().map( (mapping: any) => { return{ label:mapping.name , id:mapping.id}}))
      if(loraNode?.id !== lora.id){
        setLocalState({...localState, kpiId:"" })
      }
      setLoraNode(lora);
    }else{
      setLoraNode(undefined);
      setAvailableKPIList([])
      setLocalState({...localState, kpiId:"" })
    }


  },[localState?.loraNodeId])
 const {t} = useTranslation()
  console.log("Event UI", localState)

  return <Grid container spacing={1} >
    <GenericActionBar  state={localState} actions={props.actions}/>
    <Grid item xs={6}>
      <TextField required={true} label={t("Events.name")} fullWidth value={localState.name || ""}onChange={ event => { setLocalState({...localState, name:event.currentTarget.value})}}> </TextField>
    </Grid>


    <EasySelect key={localState.loraNodeId +"-loraNode"} translationKey={"Events.LoraNode"} values={ loraNodes.map( node => {return {id: node.id || "", label:node.name ||""}})}
                                                  value={localState?.loraNodeId ||""}
                                                  updateSelected={(id)=>{ setLocalState({...localState, loraNodeId:id})}}/>
    <EasySelect key={localState.loraNodeId +"-KPI"} translationKey={"Events.KPI"} values={ availableKPIList}
                disabled={availableKPIList.length===0}
                value={localState?.kpiId ||""}
                updateSelected={(id)=>{ setLocalState({...localState, kpiId:id})}}/>

    <Grid item xs={6}>
      <FormControlLabel control={<Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={localState.active} onChange={ event => { setLocalState({...localState, active: event.target.checked})}}/>} label={t("Events.active")} />
    </Grid>

    <Grid item xs={6}>
      <TextField error={conditionHasError()} required={true} label={t("Events.condition")} fullWidth value={localState.condition || ""} onChange={ event => { setLocalState({...localState, condition:event.currentTarget.value})}}> </TextField>
    </Grid>


    <Grid item xs={6}>
      <TextField label={t("Events.startTime")} type={"time"} fullWidth value={localState.startTime || ""} onChange={ event => { setLocalState({...localState, startTime:event.currentTarget.value})}}> </TextField>
    </Grid>
    <Grid item xs={6}>
      <TextField label={t("Events.endTime")} type={"time"} fullWidth value={localState.endTime || ""} onChange={ event => { setLocalState({...localState, endTime:event.currentTarget.value})}}> </TextField>
    </Grid>
    <Grid item xs={6}>
      <TextField label={t("Events.blockingTime")} type={"number"} fullWidth value={localState.blockingTime || ""}onChange={ event => { setLocalState({...localState, blockingTime: parseInt(event.currentTarget.value) || 0})}}> </TextField>
    </Grid>
    <Grid item xs={6}>
      <TextField required={true} label={t("Events.executionDelay")} type={"number"} fullWidth value={localState.executionDelay || ""}onChange={ event => { setLocalState({...localState, executionDelay: parseInt(event.currentTarget.value) || 0})}}> </TextField>
    </Grid>
    <Grid item xs={6}>
      <TextField  required={true} label={t("Events.numberOfExecutions")} type={"number"} fullWidth value={localState.numberOfExecutions || ""}onChange={ event => { setLocalState({...localState, numberOfExecutions: parseInt(event.currentTarget.value) || 0})}}> </TextField>
    </Grid>

    <Grid item xs={12} sx={{height: "25"}}>
       <ActionsTable updateState={ ( index, action) => {
         const newTable = [...localState.actions]
         newTable[index] = action
         setLocalState({...localState, actions:newTable})
       }} actions={localState.actions} deleteRow={(index) => {
         const newTable = [...localState.actions]
         newTable.splice(index,1)
         setLocalState({...localState, actions:newTable})

       }} addButton={<IconButton onClick={ () => { setLocalState({...localState, actions: localState.actions ?  [ ...localState.actions, { groupId:"", instructionId:"", applicationId:""}]:[{ groupId:"", instructionId:"",applicationId:""}]}) }}><AddCircle/></IconButton>
       }/>
    </Grid>

  </Grid>
}