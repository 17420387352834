import {useMemo} from "react";
import {getAuth} from "firebase/auth";

export const axiosHelper = (  ) => {

    const getAuthHeader = async () =>{
        return {
            "Authorization": await getAuth().currentUser?.getIdToken() || ""
        }
    }

    return {getAuthHeader}

}