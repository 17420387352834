import { TCreateLoraNodeDataModel } from "../CreateLoraNodeWizardWrapper";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "../../../../MaterialOnFire/custom-hooks/useTranslation";

export const ChooseEndpointStep = (props: {
  newLoraNode: TCreateLoraNodeDataModel;
  updateNewLoraNode: (newState: TCreateLoraNodeDataModel) => void;
}) => {
  const endpoints = useSelector(
    (state: any) => state["TTSSecrets"]?.items as unknown as any[]
  );
  const { t } = useTranslation();
  return (
    <Grid
      style={{
        height: "100%",
        width: "100%",
      }}
      container
    >
      <Grid item xs={6}>
        <FormControl fullWidth required={true}>
          <InputLabel>
            {t("createLoraNodeWizard.chooseEndpointStep.selectEndpoint")}
          </InputLabel>
          <Select
            value={props.newLoraNode?.applicationId || ""}
            label={t("createLoraNodeWizard.chooseEndpointStep.selectEndpoint")}
          >
            {endpoints.map((endpoint) => (
              <MenuItem
                value={endpoint.id}
                onClick={() =>
                  props.updateNewLoraNode({
                    ...props.newLoraNode,
                    applicationId: endpoint.id,
                  })
                }
              >
                {endpoint.id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
