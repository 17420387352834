import {DashboardDiagramConfig, DashboardProperties, DiagramProperties} from "../types/type";

export const useGetUpdateCoords = (localData:DashboardProperties,updateDataModel?:(localData:any)=>void) => {

    //TODO: replace with actual data from the backend


    return ( name:string, x:number, y:number , sizeX?:number, sizeY?:number) => {
           const diagrams: DashboardDiagramConfig[] = localData.diagrams.map((prop) => {
               if( prop.DiagramDefinition.name === name && sizeX && sizeY){
                   return {...prop, posX:x , posY:y, sizeX, sizeY }
               }

               if(prop.DiagramDefinition.name === name){
                    return {...prop, posX:x , posY:y }
                }
                return prop;
            });

            const updateData= {...localData, diagrams}
            if(updateDataModel){
                updateDataModel(updateData);
            }
           }
        }




