import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Text,
    Label, Tooltip
} from "recharts";
import React, {useEffect, useState} from "react";
import {DiagramProps} from "./useGetDiagramType";
import {Box} from "@mui/material";
import {TimeSeriesEntry} from "../../types/type";
import {NodeColorMap, transformToRechartsSeries} from "./RechartsHelper";
import {RotatedAxisLabel} from "./RotatedAxisLabel";
import {CustomTooltip} from "./CustomTooltip";


export const KapionBarChart = (props: DiagramProps) => {
    const [timeSeries, setTimeSeries] = useState<TimeSeriesEntry[]>([])
    const [nodes, setNodes] = useState<string[]>([])
    const [colorMap, setColorMap] = useState<NodeColorMap>({})

    useEffect(() => {

        const [series, nodes, colorPalette] = transformToRechartsSeries(props);
        setTimeSeries(series)
        setNodes(nodes)
        setColorMap(colorPalette)
    }, [props.data]);
    return <Box display={"flex"}  width="100%" height={"100%"} flexDirection={"column"} position={"relative"}>
        <ResponsiveContainer width={"100%"} height={`${100}%`}>
            <BarChart  data={timeSeries}>
                {/*@ts-ignore weil recharts richtig dumme falsche type definitions hat.....*/ }
                <Tooltip  content={<CustomTooltip/>}/>
                {props.kpis.map((kpi, index) => {

                    const orientation = (index % 2 === 0 ? "left" : "right")
                    const angleTilt = (index % 2 === 0 ? -90 : 90)
                    const YAxisPosition = (index % 2 === 0 ? "insideLeft" : "insideRight")

                    return [

                        nodes.map(node => {
                            const nodeKey = `${node}-${kpi}`
                            return <Bar key={nodeKey} yAxisId={kpi} type="monotone"
                                        dataKey={nodeKey}
                                        fill={colorMap[nodeKey]}/>
                        }).flat()
                        ,
                        <YAxis width={100} label={{ value: kpi, angle: angleTilt, position: YAxisPosition}} type={"number"} key={index} yAxisId={kpi}  orientation={orientation}/>
                    ]
                })

                }

                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <XAxis type={"category"} fontSize={"10"} dataKey="time" angle={-45} textAnchor={"end"} height={85}/>

            </BarChart>
        </ResponsiveContainer>
    </Box>
}