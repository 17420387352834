import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import {useMemo} from "react";
import {useGetKapionBackendKPIClient} from "./useGetKapionBackendKPIClient";


type KapionClients = "KPI"|"ANALYTICS";

export const getEndpointUrlFromBackendConfig = (backendConfig: backendRemoteConfig, callType: KapionClients) => {
    const url = window.location.origin
    //todo decide if we need to remove this for production
    const devEndpoint =  url.includes("localhost") ? "http://localhost:1338" : null

    switch(callType){
        case "KPI":
            return `${ devEndpoint||backendConfig.url}/api/kpi/${backendConfig.tenantId}`
        case "ANALYTICS":
            return `${devEndpoint||backendConfig.url}/api/kpi/${backendConfig.tenantId}`
        default:
            throw new Error("Client not supported")
    }
}




export const useGetKapionBackendClient = () => {
    // Compare this snippet from src/components/custom-hooks/useGetKapionBackendClient.ts:
    // import {backendRemoteConfig, BACKEND_CONFIG_PARAMETER_NAME} from "./types/firebaseRemoteConfigTypes";
    // import {useFirebaseRemoteConfig} from "./useFirebaseRemoteConfig";
    //
    // export const useGetKapionBackendClient = () => {
    //     const {remoteConfig} = useFirebaseRemoteConfig();
    //     const backendConfig = remoteConfig.get(BACKEND_CONFIG_PARAMETER_NAME) as backendRemoteConfig;
    //     return backendConfig;
    //
    const backendKPIClient = useGetKapionBackendKPIClient()

    return {backendKPIClient}

}