import {useEffect, useState} from "react";

export const useGetCSSGridTemplateColumns = (columns: number) => {

    const [templateState, setTemplateState] = useState<string>("")
    useEffect(() => {
        let stepSize = 100 / columns
        let template = ""
        for (let i = 0; i < columns; i++) {
            template += `${stepSize}% `
        }
        setTemplateState(template)

    }, [columns])


    return templateState
}
