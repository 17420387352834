import { useEffect, useState } from "react";
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import { firebaseDB } from "../MaterialOnFire/firebase-config";
import { KPIDocument } from "../nodeDetails/NodeDetails";
import { useTheme } from "@mui/material";

export type TNodeStatus = {
  status: "OK" | "WARNING" | "ERROR" | "LOADING" | "NEVER";
  timestampOfLastMessage: number;
  timeSinceLastMessage: number;
  statusMessage: string;
  color: string;
};

const ERROR_THRESHOLD = 12 * 60 * 60 * 1000;
const WARNING_THRESHOLD = 3 * 60 * 60 * 1000;

export const useGetNodeStatus = (props: { id?: string }) => {
  const [kpis, setKPIS] = useState<KPIDocument>({});
  const [status, setStatus] = useState<TNodeStatus>({
    status: "LOADING",
    color: "#FFFFFF",
    timeSinceLastMessage: 0,
    timestampOfLastMessage: 0,
    statusMessage: "",
  });

  const theme = useTheme();
  useEffect(() => {
    if (props.id) {
      let docRef = doc(firebaseDB, `/KPIEntries/entries/entries/${props.id}`);
      getDoc(docRef)
          .then(doc => {
            if (!doc.exists()) {
              setStatus({
                status: "NEVER",
                color: theme.palette.error.main,
                timeSinceLastMessage: -1,
                timestampOfLastMessage: -1,
                statusMessage: ""
              })
            }
          })

      let unsub = onSnapshot(docRef, (doc) => {
        setKPIS(doc.data() as unknown as KPIDocument);
      });

      return () => {
        unsub();
      };
    }
  }, [props.id]);

  useEffect(() => {
    const timestamp = kpis?.lastModified as unknown as number;
    if (timestamp) {
      const delta = Date.now() - timestamp;
      const newStatus: TNodeStatus = {
        status:
          delta >= ERROR_THRESHOLD
            ? "ERROR"
            : delta >= WARNING_THRESHOLD
            ? "WARNING"
            : "OK",

        color:
          delta >= ERROR_THRESHOLD
            ? theme.palette.error.main
            : delta >= WARNING_THRESHOLD
            ? theme.palette.warning.main
            : theme.palette.success.main,
        statusMessage: "",
        timestampOfLastMessage: timestamp,
        timeSinceLastMessage: delta,
      };

      setStatus(newStatus);
    }
  }, [kpis]);

  return status;
};
