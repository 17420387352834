import { useMap, useMapEvents } from "react-leaflet";
import React, { useCallback, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { divIcon, LatLngBounds, Marker } from "leaflet";
import {
  updateLoraNodesInViewPort,
  updateSelectedElement,
  updateViewPort,
  updateZoomLevel,
  ViewPort,
} from "./mapState";
import * as L from "leaflet";
import "leaflet.markercluster";
import { isPointInPolygon, getDistance } from "geolib";
import { RootState, store } from "../../store";
import { addMetersToGeoPoint } from "../../helper/geoPoint";
import { LoraNode } from "../../model/lora-node/LoraNode";
import ReactDOM from "react-dom";
import { useSnackbar } from "notistack";
import { MapPopup } from "./MapPopup";
import { HardwareType } from "../../model/hardware-type/hardwareType";
import { renderToStaticMarkup } from "react-dom/server";
import { useGetIconList } from "../MaterialOnFire/custom-hooks/useGetIconList";

import "./MapHandler.css";
import { useMaterialOnFireNavigate } from "../MaterialOnFire/custom-hooks/useMaterialOnFireNavigate";

const getBoundsAsRectangle = (bounds: LatLngBounds) => {
  let NE = bounds.getNorthEast();
  let SW = bounds.getSouthWest();
  let tr = { lat: NE.lat, lng: NE.lng };
  let bl = { lat: SW.lat, lng: SW.lng };
  let tl = { lat: NE.lat, lng: SW.lng };
  let br = { lat: SW.lat, lng: NE.lng };

  return {
    topLeft: tl,
    topRight: tr,
    bottomLeft: bl,
    bottomRight: br,
    center: { lat: bounds.getCenter().lat, lng: bounds.getCenter().lng },
  } as ViewPort;
};

const getViewportToRender = (viewPort: ViewPort) => {
  let result = { ...viewPort };

  let km = getDistance(viewPort.topLeft, viewPort.topRight);

  result.topLeft = addMetersToGeoPoint(
    viewPort.topLeft.lat,
    viewPort.topLeft.lng,
    km, // move latiude up
    -km // move Longitude to the left
  );

  result.topRight = addMetersToGeoPoint(
    viewPort.topRight.lat,
    viewPort.topRight.lng,
    km, // move latiude up
    km // move Longitude to the right
  );

  result.bottomRight = addMetersToGeoPoint(
    viewPort.bottomRight.lat,
    viewPort.bottomRight.lng,
    -km, // move latiude down
    km // move Longitude to the right
  );

  result.bottomLeft = addMetersToGeoPoint(
    viewPort.bottomLeft.lat,
    viewPort.bottomLeft.lng,
    -km, // move latiude down
    -km // move Longitude to the left
  );

  return result;
};

export default function MapHandler(props: { loraNodes: LoraNode[] }) {
  const map = useMap();
  const dispatch = useDispatch();
  const {navigate} = useMaterialOnFireNavigate();
  const icons = useGetIconList();
  const markerClusterGroup = L.markerClusterGroup({
    chunkedLoading: true,
    showCoverageOnHover: true,
    zoomToBoundsOnClick: true,
    disableClusteringAtZoom: 20,

    maxClusterRadius: (zoom) => {
      return (19 / zoom) * 20;
    },
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //let reduxNodes = useSelector((state: any) => state["loraNodes"]?.items);
  let reduxViewPort = useSelector((state: RootState) => state.map.viewPort);
  let selectedNode = useSelector(
    (state: RootState) => state.map.selectedElement
  );

  const cachedHardwareTypes = useSelector(
    (state: any) => state["hardwareTypes"]?.items as unknown as HardwareType[]
  );

  const hit = useSelector((state:RootState) => state.appBar.hit);

  const getIconFromCachedHardwareType = useCallback(
    (hardwareTypeId: string | undefined) => {
      if (!hardwareTypeId) {
        return icons.getIcon("Hub");
      }

      const hardwareType = cachedHardwareTypes.find(
        (hardwareType) => hardwareType.id === hardwareTypeId
      );
      return icons.getIcon(hardwareType?.icon || "Hub", { color: "red" });
    },
    []
  );

    useEffect(() => {
            if (hit) {
                const hitNode = props.loraNodes.find(node => node.id === hit.id);
                if(hitNode){
                    map.flyTo([hitNode.location.lat, hitNode.location.lng], 18,{animate: true, duration: 3})
                    dispatch(updateSelectedElement(hitNode))
                }
            }
        }, [hit]
    )

  useEffect(() => {
    let viewPort = getViewportToRender(
      reduxViewPort || getBoundsAsRectangle(map.getBounds())
    );

    const visibleNodes: LoraNode[] = [];
    if (!props.loraNodes) {
      return;
    }
    for (let node of props.loraNodes) {
      if (node.location && viewPort) {
        if (
          isPointInPolygon({ lng: node.location.lng, lat: node.location.lat }, [
            viewPort.topLeft,
            viewPort.topRight,
            viewPort.bottomRight,
            viewPort.bottomLeft,
          ])
        ) {
          visibleNodes.push(node);
          let marker = L.marker(
            new L.LatLng(node.location.lat, node.location.lng),
            {
              icon: divIcon({
                html: renderToStaticMarkup(
                  <div className={"marker"}>
                    <div
                      style={{
                        color: "white",
                        transform: "rotate(-45deg)",
                        textDecorationColor: "white",
                        zIndex: "300",
                        width: "75%",
                        height: "75%",
                        filter:
                          "invert(100%) sepia(0%) saturate(7468%) hue-rotate(107deg) brightness(109%) contrast(100%)",
                      }}
                    >
                      {getIconFromCachedHardwareType(node.hardwareType?.id)}
                    </div>
                  </div>
                ),
                className: "",
              }),
            }
          );
          marker.addEventListener({
            click: (event) => {
              dispatch(updateSelectedElement(node));
              L.popup( { minWidth: 500, className: "markerPopup" , autoPan:false   }).setLatLng([node.location.lat, node.location.lng]).setContent((layer) => {

                  let div = document.createElement("div");
                  div.style.minWidth = "100%";

                  ReactDOM.render(
                    <Provider store={store}>
                      <MapPopup
                        id={node.id}
                        enqueueSnackbar={enqueueSnackbar}
                        closeSnackbar={closeSnackbar}
                        hardwareType={node?.hardwareType}
                        node={node}
                        navigate={navigate}
                      />
                    </Provider>,
                    div
                  );

                  return div;
                }
               ).openOn(map)
            },
          });

          markerClusterGroup.addLayer(marker);
        }
      }
    }

    dispatch(updateLoraNodesInViewPort(visibleNodes));
    map.addLayer(markerClusterGroup);

    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [props.loraNodes, reduxViewPort]);

  useEffect(() => {
    let viewPort = getViewportToRender(
      reduxViewPort || getBoundsAsRectangle(map.getBounds())
    );
    let markerList: Marker[] = [];
    if (selectedNode) {
      for (let lamp of (selectedNode.lamp ||[])) {
        if (lamp?.geoLocation?.lng && lamp?.geoLocation?.lat) {
          if (
            isPointInPolygon(
              { lng: lamp?.geoLocation?.lng, lat: lamp?.geoLocation?.lat },
              [
                viewPort.topLeft,
                viewPort.topRight,
                viewPort.bottomRight,
                viewPort.bottomLeft,
              ]
            )
          ) {
            let marker = L.marker(
              new L.LatLng(lamp.geoLocation.lat, lamp.geoLocation.lng),

              {
                icon: L.icon({
                  iconUrl: "/icons/bulb.png",
                  iconSize: [36, 36],
                }),
              }
            );
            markerList.push(marker);
            marker.bindPopup(lamp.name || "").addTo(map);
          }
        }
      }
      return () => {
        for (let marker of markerList) map.removeLayer(marker);
      };
    }
  }, [selectedNode]);
  const mapEvents = useMapEvents({
    moveend: (event) => {
      let bounds = getBoundsAsRectangle(map.getBounds());
      dispatch(updateViewPort(bounds));
    },
    zoomend: (event) => {
      dispatch(updateZoomLevel(map.getZoom()));
    },
    click: (event) => {
      dispatch(updateSelectedElement(null));
    },
  });

  return <div></div>;
}
