import {Box, Card, CardContent, CardHeader, Stack, TextareaAutosize, Tooltip, Typography} from "@mui/material";
import {useEffect, useMemo, useRef, useState} from "react";
import {TrendingDown, TrendingUp} from "@mui/icons-material";
import {useGetTheme} from "../../custom-hooks/useGetTheme";

interface point{
    [key:string] : number,
}
interface KPICardProps{
    width: any,
    label: string,
    data: { current : number,
        previous : number},
}


export const AnalyticsTrend = ( props:KPICardProps) =>{

    const [size, setSize] = useState({height: 0, width:0})

    const ref = useRef(null)
    const [trend, setTrend] = useState( { increased: false, percent : ""})

    const theme = useGetTheme()
    useEffect(() => {
        const percent = props.data.previous === 0 ? 0 : ( props.data.current /  props.data.previous )
        const increased = percent > 1;

        setTrend({
            increased: increased,
            percent: ( ( percent - 1 ) * 100 ).toFixed(2)
        })

    }, [props.data]);

    useEffect(() => {

        if (ref) {
            //@ts-ignore
            setSize({width: ref?.current?.clientWidth, height: ref?.current?.clientHeight})

            console.log(size)
        }
    },[ref])

    return <Card ref={ref} key="KPICard" sx={ {width : props.width, height:"100%" , padding:"0.5rem", maxHeight:"100%"}}>
        <Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"flex-start"}>
            <Box width={"100%"} height={"20%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <div>
                    <Typography fontWeight={"bold"} fontSize={"2rem"}> {props.label} </Typography>
                </div>
            </Box>
            <Stack width={"100%"} height={"80%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} spacing={0} >

                <Typography fontSize={"3rem"} fontWeight={"bolder"} color={trend.increased ? "secondary" : "error"}> {(props.data.current/1000).toFixed(2)}k </Typography>
                <Typography fontSize={"2rem"}>
                    {trend.increased ? <TrendingUp color={"secondary"}/> : <TrendingDown color={"error"}/>} { trend.percent} %
                </Typography>
            </Stack>
        </Box>

    </Card>
}