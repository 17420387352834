import { Route, RouteObject, Routes } from "react-router-dom";
import LandingPage from "./landing-page/LandingPage";
import React, { ReactNode, useEffect, useState } from "react";
import {
  CustomRoute,
  UiEnhancements,
  useGetRoutes,
} from "./custom-hooks/useGetRoutes";
import { useUIServices } from "./custom-hooks/useUIServices";
import { uiFirebaseServices } from "./UI-Builder/uiBuilderServices/uiFirebaseServices";
import { fetchAllCachedUIDefinitions } from "./UI-Builder/uiBuilderServices/uiDefinitionState";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicUiIsInitialized } from "./DynamicUIFrameworkState";
import { Box, LinearProgress, Toolbar } from "@mui/material";
import PrimarySearchAppBar from "./app-bar/PrimarySearchAppBar";

export const DynamicRoutes = (props: {
  uiEnhancers?: Map<string, UiEnhancements>;
  customRoutes?: CustomRoute[];
}) => {
  const { getRoutes, mapCustomRouteToRoute, setAppSpecificRoutes } =
    useGetRoutes(props.uiEnhancers);

  const uiServices = useUIServices();
  const dispatch: AppDispatch = useDispatch();
  const uiFrameWorkIsInitialized = useSelector(
    (state: RootState) => state.dynamicUi.isInitialized
  );

  const [applicationRoutes, setApplicationRoutes] = useState<CustomRoute[]>([]);

  useEffect(() => {
    //this will initialize the UI-Definitions State for the whole application
    //further dispatches of this function are only needed in useUIServices in case a
    //Application needs access to Uis before Dynamic Routes
    dispatch(fetchAllCachedUIDefinitions());
  }, []);

  useEffect(() => {
    if (props.customRoutes) setAppSpecificRoutes(props.customRoutes);
  }, [props.customRoutes]);
  useEffect(() => {
    if (uiServices.size === 0) {
    }
    setApplicationRoutes(getRoutes());
    if (!uiFrameWorkIsInitialized) {
      dispatch(setDynamicUiIsInitialized(true));
    }
  }, [
    uiServices,
    uiFrameWorkIsInitialized,
    props?.customRoutes,
    props?.uiEnhancers,
  ]);

  return uiFrameWorkIsInitialized ? (
    <Box
        key={"AppBarBox"}
      sx={{
        display: "flex",
        height: "100%",
        backgroundColor: "paper.default",
      }}
    >
      <PrimarySearchAppBar key={"SearchBar"} />
      {/*Rest of the Page pre styled*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
          width: "100%",
          mx: 1,
        }}
      >
        {/*AppBar height*/}
        <Toolbar sx={{ height: "auto" }} />

        {/*Body*/}
        <Box
          id={"rootBox"}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            //backgroundColor: "background.default",
            overflow: "auto",
            my: 1,
          }}
        >
          <Routes>
            <Route path={"/"} element={<LandingPage />} />
            {applicationRoutes.map((route) => {
              return (
                <Route
                  path={route.route.replace("$", ":id")}
                  element={route.component}
                />
              );
            })}
            {props.customRoutes
              ? mapCustomRouteToRoute(props.customRoutes)
              : ""}
          </Routes>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
};
