import {
  collection,
  doc,
  getDocsFromCache,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
  FirestoreError,
} from "firebase/firestore";
import {
  fireBaseApp,
  firebaseDB,
} from "../../components/MaterialOnFire/firebase-config";

import { LoraNode } from "./LoraNode";
import { ttnDevice } from "../../backend/theThingsNetworkAPI";
import { registerCacheListener } from "../../components/MaterialOnFire/UI-Builder/uiBuilderServices/CacheListener";
import { AppDispatch } from "../../store";
import loraNodeState, {
  addLoraNode,
  addTTnDevice,
  deleteLoraNode,
  updateLoraNode,
} from "./loraNodeState";
import { generateCRUDFirebaseServices } from "../../components/MaterialOnFire/UI-Builder/uiBuilderServices/BaseFireBaseServices";
import { getFunctions, httpsCallable } from "firebase/functions";
import { HSW_INSTRUCTION_SET } from "./HSW_INSTRUCTIONS";

const loraNodeCRUDServices = generateCRUDFirebaseServices<LoraNode>(
  "/loraNodes",
  "loraNodes",
  (item) => updateLoraNode(item),
  (item) => deleteLoraNode(item)
);

const discoveredTTNNodesCRUDServices = generateCRUDFirebaseServices<ttnDevice>(
  "/discoveredTTNNodes",
  "discoveredTTNNodes",
  (item) => addTTnDevice(item),
  () => {}
);

const registerLoraNodeListeners = (dispatch: AppDispatch) => {
  let unsubscribe1 =
    loraNodeCRUDServices.registerItemSnapShotListener(dispatch);
  let unsubscribe2 =
    discoveredTTNNodesCRUDServices.registerItemSnapShotListener(dispatch);

  return () => {
    unsubscribe1();
    unsubscribe2();
  };
};

const getSendDownlinkFunction = () => {
  const functions = getFunctions(fireBaseApp, "europe-west1");
  const downlink = httpsCallable(functions, "downlinkManagement-enqueueDownlink");

  return downlink;
};

const switchLoraNode = async (
  applicationId: string,
  device_id: string,
  status: "on" | "off"
) => {
  return getSendDownlinkFunction()({
    applicationId: applicationId,
    device: device_id,
    payload: HSW_INSTRUCTION_SET[status],
  });
};

const createLoraNodeFromDiscoveredTTNDevice = async (ttnDevice: ttnDevice) => {
  try {
    let payload: LoraNode = {
      name: ttnDevice.ids.dev_eui,
      ttnPayload: ttnDevice,
      lamp: [],
      loraType: {
        id: "HSW",
        actions: [],
      },
      location: {
        //@ts-ignore
        lat: ttnDevice?.locations?.user?.latitude,
        //@ts-ignore
        lng: ttnDevice?.locations?.user?.longitude,
      },
      uniqueId: ttnDevice.ids.dev_eui,
      id: ttnDevice.ids.dev_eui,
      deleted: false,
    };
    await setDoc(
      doc(firebaseDB, "/loraNodes", ttnDevice.ids.dev_eui),
      payload
    ).then(
      async () =>
        await updateDoc(
          doc(firebaseDB, "/discoveredTTNNodes", ttnDevice.ids.dev_eui),
          { deleted: true }
        )
    );
    let indexPayload: any = {};
    indexPayload[ttnDevice.ids.dev_eui] = ttnDevice.updated_at;
    await updateDoc(
      doc(firebaseDB, "/applicationIndex", "takenOverDevices"),
      indexPayload
    ).catch(async (e: FirestoreError) => {
      if (e.code === "not-found") {
        await setDoc(
          doc(firebaseDB, "/applicationIndex", "takenOverDevices"),
          indexPayload
        );
      }
    });
  } catch (e) {
    throw e;
  }
};

const readDiscoveredTTNDevices = async () => {
  const loraNodes = query(collection(firebaseDB, "/loraNodes"));
  const discoveredLoraNodes = query(
    collection(firebaseDB, "/discoveredTTNNodes")
  );
  const loraNode: LoraNode[] = [];
  try {
    const snapshotLoraNodes = await getDocsFromCache(loraNodes);
    snapshotLoraNodes.forEach((doc) => {
      loraNode.push(doc.data() as LoraNode);
    });
  } catch (e) {
    console.log(e);
  }
  const ttnNodes: ttnDevice[] = [];
  try {
    const snapshotDiscoveredLoraNodes = await getDocsFromCache(
      discoveredLoraNodes
    );

    snapshotDiscoveredLoraNodes.forEach((doc) => {
      let localNode = doc.data() as ttnDevice;
      if (
        loraNode.findIndex(
          (value) => localNode?.ids?.dev_eui === value?.ttnPayload?.ids?.dev_eui
        ) < 0
      ) {
        ttnNodes.push(localNode);
      }
    });
  } catch (e) {
    console.log(e);
  }

  return ttnNodes;
};

export {
  loraNodeCRUDServices,
  getSendDownlinkFunction,
  discoveredTTNNodesCRUDServices,
  registerLoraNodeListeners,
  readDiscoveredTTNDevices,
  createLoraNodeFromDiscoveredTTNDevice,
  switchLoraNode,
};
