import { useTranslation } from "../../custom-hooks/useTranslation";
import {Autocomplete, Box, Grid, GridSize, TextField} from "@mui/material";
import React from "react";

export const EasySelect = (props: {
  translationKey: string,
  xs?:boolean | GridSize | undefined,
  disabled?: boolean,
  values: { id: string, label: string }[],
  value: string,
  displayAsBox?: boolean,
  updateSelected: (id: string) => void
    width?: string
}) => {
  const { t } = useTranslation();
  return props.displayAsBox ? <Box width={props.width ||"100%"}>
        <Autocomplete
            fullWidth={true}
            options={props.values}
            disabled={props.disabled}
            value={props.values.find(value => value.id === props.value) || null}
            onChange={(event, value, reason, details) => {
              if (value) props.updateSelected(value.id);
            }}
            renderInput={(params) => <TextField variant="standard" required={true} {...params} label={t(props.translationKey)} />}
        />
      </Box>:
      <Grid item xs={props.xs||6}>

    <Autocomplete

      options={props.values}
      disabled={props.disabled}
      value={props.values.find(value => value.id === props.value) || null}
      onChange={(event, value, reason, details) => {
        if (value) props.updateSelected(value.id);
      }}
      renderInput={(params) => <TextField required={true} {...params} label={t(props.translationKey)} />}
    />


  </Grid>;

};