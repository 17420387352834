import { CustomRoute, UiEnhancements } from "../custom-hooks/useGetRoutes";
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Paper,
  responsiveFontSizes,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { DynamicRoutes } from "../DynamicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useGetTheme } from "../custom-hooks/useGetTheme";
import { useUIServices } from "../custom-hooks/useUIServices";
import { fetchAllCachedUIDefinitions } from "../UI-Builder/uiBuilderServices/uiDefinitionState";
import { uiFirebaseServices } from "../UI-Builder/uiBuilderServices/uiFirebaseServices";
import {useRegisterAlertPipelines} from "../../custom-hooks/useRegisterAlertPipelines";

interface LoadingScreenInterface {
  uiEnhancers?: Map<string, UiEnhancements>;
  customRoutes?: CustomRoute[];
}

interface LoadingComplete {
  [service: string]: boolean;
}

const EXPERIENCE_ENHANCER = 1000;

export const LoadingScreen = (props: LoadingScreenInterface) => {
  const [serviceInitComplete, setServiceInitComplete] =
    useState<LoadingComplete>({});
  const [dataModelInitComplete, setDataModelInitComplete] = useState(false);
  const [applicationInitComplete, setApplicationInitComplete] = useState(false);
  const theme = useGetTheme();
  const [loginMessage,setLoginMessage] = useState<String>("");
  const loginMessages = ["Entspannen Sie sich und beobachten Sie, wie unsere Pixel-Eichhörnchen die Daten hin und her jonglieren!","Bitte behalten Sie die Nerven, während unsere Hamster auf den Datenautobahnen Rennen fahren!", "Lehnen Sie sich zurück und lassen Sie unsere virtuellen Einhörner die Datenmagie entfesseln!", "Bereiten Sie sich auf eine intergalaktische Datenreise vor, während unsere Außerirdischen die Informationen in Lichtgeschwindigkeit beamen.", "Bitte warten Sie einen kleinen Augenblick, bis unsere Brieftauben alle Datenpakte für Sie ausgeliefert haben."]
  const dispatch: AppDispatch = useDispatch();
  const uiServices = useUIServices();
  const pipelines = useRegisterAlertPipelines()

  const reduxStore = useSelector((state: RootState) => {
    return state;
  });

  const initRef = useRef(serviceInitComplete);
  const updateInitRef = (key: string, status: boolean) => {
    initRef.current[key] = status;
    setServiceInitComplete({ ...initRef.current });
  };

  useEffect(() => {
    //this will initialize the UI-Definitions State for the whole application
    //further dispatches of this function are only needed in useUIServices in case a
    //Application needs access to Uis before Dynamic Routes
    dispatch(fetchAllCachedUIDefinitions());
    setLoginMessage(loginMessages[Math.floor(Math.random()*loginMessages.length)])
  }, []);

  useEffect(() => {
    if (!dataModelInitComplete && !applicationInitComplete) {
      const promises: Promise<any>[] = [];

      if (uiServices.size === 0) {
        uiFirebaseServices
          .readDeltaItemsAsOfTimestamp()
          .then(() => dispatch(fetchAllCachedUIDefinitions()));
      }

      uiServices.forEach((service, key) => {
        promises.push(service?.crudService?.readDeltaItemsAsOfTimestamp());
      });

      Promise.all(promises).then(() => {
        uiServices.forEach((service, key) => {
          dispatch(service?.fetchAll());
          updateInitRef(key, true);
        });
      });
    }
  }, [uiServices]);

  useEffect(() => {
    let isInitialized = true;
    let keys = Object.keys(serviceInitComplete);
    if (keys.length === 0) isInitialized = false;
    for (let key of keys) {
      if (!initRef.current[key]) {
        isInitialized = false;
      }
    }
    setDataModelInitComplete(isInitialized);
  }, [serviceInitComplete]);

  useEffect(() => {
    if (!applicationInitComplete && dataModelInitComplete) {
      let initComplete = true;
      uiServices.forEach((service, key) => {
        //@ts-ignore
        if (!reduxStore[key]?.initialized) {
          initComplete = false;
        }
      });

      if (initComplete) {
        console.info("Application initialized");
      }
      const to = setTimeout(
        () => setApplicationInitComplete(initComplete),
        EXPERIENCE_ENHANCER
      );

      return () => clearTimeout(to);
    }
  }, [dataModelInitComplete, reduxStore]);

  useEffect(() => {
    if (applicationInitComplete) {
      const unsubscribeFunctions: any[] = [];
      uiServices.forEach((service, key) => {
        unsubscribeFunctions.push(
          service?.crudService.registerItemSnapShotListener(dispatch)
        );
      });

      const unsubscribeUIDefinitions =
        uiFirebaseServices.registerItemSnapShotListener(dispatch);

      return () => {
        for (let unsub of unsubscribeFunctions) {
          try {
            unsub();
          } catch (e) {
            console.error("Couldn't unsubscribe");
          }
        }
        unsubscribeUIDefinitions();
      };
    }
  }, [applicationInitComplete]);

  return applicationInitComplete ? (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <BrowserRouter>
        <DynamicRoutes
          uiEnhancers={props.uiEnhancers}
          customRoutes={props.customRoutes}
        />
      </BrowserRouter>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <Paper>
        <Box sx={{ width: "100vw", height: "100vh" }}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "100%", width: "100%" }}
          >
            <Grid
              item
              xs={4}
              sx={{ height: "50vh", width: "50vh" }}
              justifyContent={"center"}
            >
              <Paper sx={{ width: "50vh", height: "flexWrap" }}>
                <Stack spacing={2} sx={{ height: "100%", width: "100%" }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    <img src={"/logo.png"} style={{ width: "100%" }} />
                  </Typography>
                  <LinearProgress color={"secondary"} />
                  <Typography>
                    {loginMessage}
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};
