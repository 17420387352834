import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ReactNode} from "react";

export enum themeMode {
  light = "light",
  dark = "dark",
}

export interface HitListItem {
  label: string,
  id: string,
}

export interface AppBarState {
  searchString: string;
  searchEnabled: boolean;
  drawerOpen: boolean;
  themeMode: themeMode | string;
  hitList: HitListItem[];
  hit: HitListItem | null;
  activeComponent: string | null;
}

const initialState: AppBarState = {
  searchString: "",
  searchEnabled: false,
  drawerOpen: false,
  themeMode: themeMode.light,
  hitList: [],
  hit: null,
  activeComponent : null
};

export const appBarSlice = createSlice({
  name: "appBar",
  initialState,
  reducers: {
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setSearchEnabled: (state, action: PayloadAction<boolean>) => {
      state.searchEnabled = action.payload;
    },
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<themeMode>) => {
      state.themeMode = action.payload;
      localStorage.setItem("themeMode", action.payload.toString());
    },
    setHitlist: (state, action: PayloadAction<HitListItem[]>) => {
      state.hitList = action.payload;
    },
    setHit: (state, action: PayloadAction<HitListItem | null>) => {
      console.log(action.payload)
      state.hit = action.payload;
    },
    setActiveComponent: (state, action: PayloadAction<string | null>) => {
      state.activeComponent = action.payload;
    },
    cleanup: (state, action: PayloadAction) => {
      state.activeComponent = null;
      state.hit = null;
      state.hitList = []
      //TODO: also clear selectedItem of generic List etc.
    }
  },
});

export const {
  setSearchString,
  setSearchEnabled,
  setDrawerOpen,
  setThemeMode,
  setHitlist,
  setHit,
  setActiveComponent,
  cleanup
} = appBarSlice.actions;
export const appBarReducer = appBarSlice.reducer;
