import {AppBarAlert} from "./AppBapAlertManager";
import {Box, Card, CardContent, CardHeader, Drawer, IconButton, List, ListItem, Paper, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useMaterialOnFireNavigate} from "../custom-hooks/useMaterialOnFireNavigate";
import Toolbar from "@mui/material/Toolbar";
import {ChevronRight} from "@mui/icons-material";
import {useTranslation} from "../custom-hooks/useTranslation";


export const AlertList = ( props:{
    toggled: boolean,
    alerts: AppBarAlert[]
    setClose: () => void
}) => {

    const {navigate} = useMaterialOnFireNavigate()
    const {t} = useTranslation()

    return props.toggled ? <Box component={Paper}  height={"100vh"} width={"15vw"} position={"absolute"}  top={0} right={0} zIndex={9999} overflow={"hidden"}>
        <Box

            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                px: [1],
            }}
        >
            <IconButton>

                <ChevronRight onClick={props.setClose}/>

            </IconButton>
            <Typography>
                {t("appBar.Alerts.Headline")}
            </Typography>


        </Box>


        <Box overflow={"auto"} height={"100%"} width={"100%"} >
        <List>
            {
                props.alerts.map(alert => {
                    return <ListItem key={alert.key} onClick={ () =>{
                        navigate("..", alert.link, "")
                        props.setClose()
                    }}>
                        <Card sx={{borderColor:(theme) => theme.palette.error.main , borderWidth:"0.1rem", borderStyle:"dashed" , cursor:"pointer"}}>
                            <CardContent>
                                <Typography  align={"center"} >{alert.title}</Typography>
                                <Box height={"0.25rem"} width={"100%"} />
                                <Typography variant={"caption"}> {alert.message} </Typography>
                                </CardContent>

                        </Card>
                   <span/>
                    </ListItem>
                })

            }

        </List>
        </Box>
    </Box> :null




}