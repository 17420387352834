import {DiagramProps} from "./useGetDiagramType";
import {TimeSeriesEntry} from "../../types/type";
import {useEffect, useState} from "react";
import {transformToRechartsSeries} from "./RechartsHelper";
import {Box, Paper, Tooltip, Typography} from "@mui/material";
import {KPICard} from "../../../../../nodeDetails/KPI-Card";


export const KapionKPIChart = (props:DiagramProps) => {
    const [timeSeries, setTimeSeries] = useState<TimeSeriesEntry|null>(null)
    const [nodes, setNodes] = useState<string[]>([])

    useEffect(() => {

            const [series, nodes ] = transformToRechartsSeries(props);
            setTimeSeries( series?.length > 0?series?.reduce( ( previousValue, currentValue, currentIndex, array)=>{
                return {...previousValue, ...currentValue, [currentValue.nodeId+"-time"]: currentValue.time, [previousValue.nodeId+"-time"]: previousValue.time}
            }) : null)

            setNodes(nodes)
    }, [props]);
    return  <Box display={"flex"} flexGrow={1} flexDirection={"row"} position={"relative"} flexWrap={"wrap"} gap={"0.5rem"} alignContent={"flex-start"} justifyContent={"center"} margin="0.2rem">

        {
           nodes.map(node => {

                    if (!timeSeries) {
                        return <></>

                    }
                    console.log("series",timeSeries)
                    const key = `${node}-${props.kpis[0]}`
                    return <Paper key={node} sx={{ padding:0, margin:0, alignContent:"flex-start", justifyContent:"center", display:"flex", flexDirection:"column", width:nodes.length>1 ? "45%" : "100%" , minHeight:nodes.length > 1 ? `${98 / Math.ceil(nodes.length / 2)}%`: "100%" }}>
                        <Box key={"headline"} height="10%"  width={"100%"} margin={"0.5rem"}>
                            <Typography align={"center"} >
                                {node}
                            </Typography>
                        </Box>

                        <Box key={"kpi"} flexGrow={1}  alignContent={"center"} justifyContent={"center"}>
                            <Tooltip title={ new Date(timeSeries[node+"-time"]).toLocaleString()}  >
                            <Typography align={"center"} variant={"h4"} noWrap={true} color={"secondary"}>
                                {timeSeries[key]}
                            </Typography>
                            </Tooltip>
                        </Box>

                    </Paper>
                })

        }
        
        
    </Box>
}