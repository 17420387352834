import { UiEnhancements } from "../MaterialOnFire/custom-hooks/useGetRoutes";

import { useGetDiscoveredTTNNodesUIEnhancer } from "./uiEnhancers/useGetDiscoveredTTNNodesUIEnhancer";
import { useGetLoraNodesUIEnhancer } from "./uiEnhancers/useGetLoraNodesUIEnhancer";
import { FormatPaint } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "../MaterialOnFire/custom-hooks/useTranslation";
import { useGetUserUIEnhancer } from "./uiEnhancers/useGetUserUIEnhancer";
import { useGetGatewayUIEnhancer } from "./uiEnhancers/useGetGatewayUIEnhancer";
import { useGetGroupUiEnhancer } from "./uiEnhancers/useGetGroupUiEnhancer";
import { useGetEventUIEnhancer } from "./uiEnhancers/useGetEventUIEnhancer";
import {useGetDashboardUIEnhancer} from "./uiEnhancers/useGetDashboardUIEnhancer";
import {useGetDiagramConfigEnhancer} from "./uiEnhancers/useGetDiagramConfigEnhancer";
import {useRegisterAlertPipelines} from "./useRegisterAlertPipelines";
import {useGetHardwareTypeUIEnhancer} from "./uiEnhancers/useGetHardwareTypeUIEnhancer";
import {useGetKPIMappingUIEnhancer} from "./uiEnhancers/useGetKPIMappingIndexUiEnhancer";

export const useGetUiEnhancers = () => {
  const enhancers = new Map<string, UiEnhancements>();
  const discoveredTTNNodesEnhancer = useGetDiscoveredTTNNodesUIEnhancer();
  const loraNodeEnhancer = useGetLoraNodesUIEnhancer();
  const userEnhancer = useGetUserUIEnhancer();
  const gatewayEnhancer = useGetGatewayUIEnhancer();
  const groupEnhancer = useGetGroupUiEnhancer();
  const eventEnhancer = useGetEventUIEnhancer()
  const dashboardEnhancer = useGetDashboardUIEnhancer()
  const diagramEnhancer = useGetDiagramConfigEnhancer()
  const hardwareTypeEnhancer = useGetHardwareTypeUIEnhancer()
  const kpimappingEnhancer = useGetKPIMappingUIEnhancer()
  const snackbar = useSnackbar();


  const { t } = useTranslation();

  enhancers.set(
    discoveredTTNNodesEnhancer.key,
    discoveredTTNNodesEnhancer.enhancer
  );
  enhancers.set(loraNodeEnhancer.key, loraNodeEnhancer.enhancer);
  enhancers.set(userEnhancer.key, userEnhancer.enhancer);
  enhancers.set(gatewayEnhancer.key, gatewayEnhancer.enhancer);
  enhancers.set(groupEnhancer.key, groupEnhancer.enhancer);
  enhancers.set(eventEnhancer.key,eventEnhancer.enhancer)
  enhancers.set(dashboardEnhancer.key,dashboardEnhancer.enhancer)
  enhancers.set(diagramEnhancer.key,diagramEnhancer.enhancer)
  enhancers.set(hardwareTypeEnhancer.key,hardwareTypeEnhancer.enhancer)
  enhancers.set(kpimappingEnhancer.key,kpimappingEnhancer.enhancer)

  enhancers.set("Theme", {
    enhanceActionBar: (item, defaultActionbar) => {
      return [
        ...defaultActionbar,
        {
          action: (newState: any) => {
            localStorage.setItem("preferredThemeId", newState?.id);
            snackbar.enqueueSnackbar(t("Theme.RefreshPageToShowTheme"), {
              variant: "info",
            });
          },
          name: "Set active",
          icon: <FormatPaint />,
        },
      ];
    },
  });

  return enhancers;
};
