import {AppBarAlert} from "../../MaterialOnFire/app-bar/AppBapAlertManager";
import {fetchLastDataFromBackend} from "../uiEnhancers/Dashboards/DiagramHelper/useGetDiagramData";
import {
    ALERT_SETTINGS_PARAMETER_NAME, alertSettings,
    BACKEND_CONFIG_PARAMETER_NAME,
    backendRemoteConfig
} from "../../MaterialOnFire/custom-hooks/types/firebaseRemoteConfigTypes";
import {DiagramProperties, TimeSeriesData} from "../uiEnhancers/Dashboards/types/type";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {LoraNode} from "../../../model/lora-node/LoraNode";
import {HardwareType} from "../../../model/hardware-type/hardwareType";
import {useGetFirebaseConfigParameter} from "../../MaterialOnFire/custom-hooks/useGetFirebaseConfigParameter";
import {useCallback} from "react";


const DETECTION_TIME_HOURS = 36

export const useGetOfflineDevicesAlertPipeline = () =>{

    const loraNodes = useSelector((state:any) => (state["loraNodes"]?.items as LoraNode[])?.filter((node:LoraNode) => !node.deleted))
    const hardwareTypes = useSelector((state:any) => state["hardwareTypes"]?.items as HardwareType[])
    const backendConfig = useGetFirebaseConfigParameter<backendRemoteConfig>(BACKEND_CONFIG_PARAMETER_NAME)

    const alertParam = useGetFirebaseConfigParameter<alertSettings>(ALERT_SETTINGS_PARAMETER_NAME)

    const fetchAlertsCallback = useCallback(  async():Promise<AppBarAlert[]> => {


            if (!backendConfig || !alertParam) {
                return []
            }

            const promises = hardwareTypes.map((hardwareType:HardwareType) => {
            const diagramProps:DiagramProperties = {
                name: "Diagram",
                diagramProperties: {
                    duration: alertParam.DeviceOfflineDetectionTimeHours,
                    durationUnit: "h",
                    startTime: "now()",
                    diagramType: "kpi",
                    aggregationInterval: "1h",
                    aggregationStrategy: "mean",
                },
                id: "1",
                influxQueryProperties: {
                    nodeIds: [".*"],
                    values: [".*"],
                    influxBucketName: hardwareType.name
                }
            }

            return fetchLastDataFromBackend(
                backendConfig,
                diagramProps,
                hardwareType.name)
        })

            const lastData = (await Promise.all(promises)).map((data :TimeSeriesData) => data.timeSeries).flat()
            const distinctLastDataNodeIds = lastData.map((entry) => entry.nodeId).filter((value, index, self) => self.indexOf(value) === index)
            const offlineNodes = loraNodes.filter((node) => !distinctLastDataNodeIds.includes(node.id))

            return offlineNodes.map((node) => {
                return {
                    key: `offline_${node.id}`,
                    title: `${node.name} nicht erreichbar`,
                    message: `Das Gerät "${node.name}" mit der id "${node.id}" hat sich seit > ${alertParam.DeviceOfflineDetectionTimeHours}h nicht gemeldet.`,
                    link: `/loraNodes/${node.id}`,
                    severity: 'error'
                }
            })



    },[backendConfig,loraNodes,hardwareTypes,alertParam])



    return {fetchAlerts:fetchAlertsCallback}
    }
