import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fireBaseApp } from "../firebase-config";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { QuestionMark, Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "../custom-hooks/useTranslation";

import {

  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  setPersistence,
  browserSessionPersistence
} from "firebase/auth";

import { useSnackbar } from "notistack";

export default () => {
  //TODO Fix login
  const { t } = useTranslation();
  const [loginName, setLoginName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const auth = getAuth(fireBaseApp);

  useEffect( () =>{
    setPersistence( auth, browserSessionPersistence)
  },[])

  const { enqueueSnackbar } = useSnackbar();

  const signIn =  () => signInWithEmailAndPassword(auth, loginName, password)
    .then( )
    .catch(function (error) {

      if (error.code == 'auth/multi-factor-auth-required') {

        const resolver = getMultiFactorResolver(auth, error);
        // Ask user which second factor to use.
        if (resolver.hints[0].factorId ===
          PhoneMultiFactorGenerator.FACTOR_ID) {

          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          // Send SMS verification code

          const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', { size: "invisible" }, auth);
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
              // Ask user for the SMS verification code. Then:

              const secondFactor = window.prompt("Bitte geben Sie Ihr SMS-OTP ein") || "";
              const cred = PhoneAuthProvider.credential(
                verificationId,secondFactor );
              const multiFactorAssertion =
                PhoneMultiFactorGenerator.assertion(cred);
              // Complete sign-in.
              return resolver.resolveSignIn(multiFactorAssertion)
            })
            .then( async function (userCredential) {
              console.log("Firebase-Token:", await userCredential.user.getIdToken())
              // User successfully signed in with the second factor phone number.
            });
        } else {
          // Unsupported second factor.
        }
      } else if (error.code == 'auth/wrong-password') {
        // Handle other errors such as wrong password.
      }
    })

  return (
    <Grid
      container
      justifyContent={"center"}
      alignContent={"center"}
      sx={{ height: "100%", width: "100%", backgroundColor: "paper.dark" }}
      onKeyUp={(event) => { if ( event.key === 'Enter') signIn() }}
    >
      <Grid
        item
        container
        justifyContent={"center"}
        spacing={2}
        alignItems={"center"}
        sx={{ width: "40%", p: 4, backgroundColor: "paper.light" }}
        component={Paper}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <img src={"/logo.png"} style={{ width: "27vw" }} />
        </Grid>
        <Grid item container xs={12} justifyContent={"center"}>
          <TextField
            label={t("login.loginName")}
            fullWidth
            onChange={(event) => setLoginName(event.currentTarget.value)}
            name={"login"}
            value={loginName}
          />
        </Grid>
        <Grid item container xs={12} justifyContent={"center"}>
          <FormControl
            variant="outlined"
            sx={{
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("login.password")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event: React.MouseEvent) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <div
            id="recaptcha-container"
            className="justify-center flex"
          >
          </div>
        </Grid>
        <Grid item container xs={6} justifyContent={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) =>
              signIn()
            }
          >
            <Typography>{t("login.login")}</Typography>
          </Button>
        </Grid>

        <Grid item xs={6} justifyContent={"center"}>
          <Button
            variant={"outlined"}
            onClick={() => {
              const auth = getAuth(fireBaseApp);
              sendPasswordResetEmail(auth, loginName)
                .then(() => {
                  enqueueSnackbar(`Email an ${loginName} gesendet`, {
                    variant: "info",
                  });
                })
                .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
            }}
          >
            {t("login.forgetPassword")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
