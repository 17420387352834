import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { AnyAction } from "redux";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Popover,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { More, MoreVert } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";

import { UiDefinition } from "../UI-Builder/uiBuilderServices/uiDefinition";
import { useTranslation } from "../custom-hooks/useTranslation";

export interface GenericActionBarAction {
  name: string;
  icon?: ReactNode;
  disabled?: boolean;
  action:  (newState?: any, uiDefinition?: UiDefinition) => Promise<void> | void | AnyAction;
}

const DEFAULT_THRESHOLD = 2;
export const GenericActionBar = (props: {
  state: any;
  actions: GenericActionBarAction[];
  threshold?: number;
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "Actions",
  });
  const [actions, setActions] = useState<ReactNode[]>([]);
  const [hiddenActions, setHiddenActions] = useState<ReactNode[]>([]);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const snackBar = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const visibleActions: ReactNode[] = [];
    const popOverActions: ReactNode[] = [];

    if (props.actions) {
      for (let i = 0; i < props.actions.length; i++) {
        const action = props.actions[i];
        let threshold = DEFAULT_THRESHOLD;
        if (props?.threshold !== undefined) threshold = props.threshold;

        if (i < threshold) {
          visibleActions.push(
            <Grid item xs={1}>
              <Tooltip title={action.name}>
                <IconButton
                  disabled={action.disabled}
                  onClick={(event) => {
                    action.action(props.state);
                  }}
                >
                  {action.icon || action.name}
                </IconButton>
              </Tooltip>
            </Grid>
          );
        } else {
          popOverActions.push(
            <ListItemButton
              key={i}
              onClick={(event) => {
                action.action(props.state);
              }}
            >
              <Tooltip title={action.name} placement={"left"}>
                <IconButton disabled={action.disabled}>
                  {action.icon || action.name}
                </IconButton>
              </Tooltip>
            </ListItemButton>
          );
          popOverActions.push(<Divider />);
        }
      }
    }
    setActions(visibleActions);
    setHiddenActions(popOverActions);
  }, [props?.actions, props?.threshold, props.state]);

  if (props.actions.length === 0) {
    return <></>;
  }

  return smallScreen ? (
    <SpeedDial
      ariaLabel="Actions"
      sx={{ position: "absolute", bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      {props.actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={<IconButton color={"secondary"}>{action.icon}</IconButton>}
          tooltipOpen
          color={"primary"}
          tooltipTitle={
            <Typography fontSize={12} color={"black"}>
              {action.name}
            </Typography>
          }
        />
      ))}
    </SpeedDial>
  ) : (
    <Grid container item xs={12} sx={{ height: "auto", py: 1 }}>
      <Grid container item xs={11}>
        {actions}
      </Grid>{" "}
      { hiddenActions?.length > 1 ?<Grid item xs={1}>
        <IconButton id={"MoreButton"} {...bindTrigger(popupState)}>
          <MoreVert />
        </IconButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <List>{hiddenActions}</List>
        </Popover>
      </Grid>:null
      }
    </Grid>
  );
};
